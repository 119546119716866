/**
 * @module Buttons
 */
import React from 'react'
import PropTypes from 'prop-types'

export * from './add-block'
export * from './rounded-button'

/**
 * The base button.
 *
 * @param {object} props - The component props object.
 * @param {string} props.text - The button text.
 * @param {Function} props.onClick - The button click handler.
 *
 * @returns {React.ReactElement} - The base button component.
 *
 * @example
 * return (
 *   <BaseButton text="Click Me" onClick={() => {}} />
 * )
 */
export function BaseButton({ text, onClick, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <button onClick={onClick} {...props}>
      {text}
    </button>
  )
}

BaseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}
