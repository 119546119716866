/**
 * @module DropAreaUploading
 */

import React from 'react'
import PropTypes from 'prop-types'
// TODO PP-410: Lottie is breaking jest tests in partner portal
// import Lottie from 'lottie-react'
import { Typography, Box, LinearProgress } from '@mui/material'
// import uploadingAnimation from 'assets/lottie/lottie-upload.json'

/**
 * Display this component while your block is busy handling an asset upload.
 *
 * @alias module:DropAreaUploading
 *
 * @param {object} props - Component props object.
 * @param {string} [props.fileType] - Type of file to be uploaded. (i.e. 'image', 'audio', 'video').
 * @param {string} [props.description] - Descriptive text.
 *
 * @returns {React.ReactElement} - The visual component for a Block's "uploading" state. (E.g., what gets displayed while a file is uploading or finalizing).
 */
export function DropAreaUploading({ fileType, description }) {
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width="100%"
    >
      <Box width="50%" mb={2}>
        <LinearProgress />
      </Box>
      {/* <Lottie animationData={uploadingAnimation} height={128} width={96} /> */}
      <Typography color="textSecondary">
        {description ?? `Uploading ${fileType}`}&hellip;
      </Typography>
    </Box>
  )
}

DropAreaUploading.propTypes = {
  /**
   * Type of file to be uploaded. (i.e. 'image', 'audio', 'video')
   * This is used in the text to describe specific file to upload.
   */
  fileType: PropTypes.string,
  description: PropTypes.string,
}

DropAreaUploading.defaultProps = {
  fileType: '',
}
