/**
 * @module DropAreaPending
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Box, LinearProgress } from '@mui/material'

/**
 * Display this component when the block content is still processing api-side.
 *
 * @alias module:DropAreaPending
 *
 * @param {object} props - Component props object.
 * @param {string} [props.fileType] - Type of file to be uploaded. (i.e. 'image', 'audio', 'video').
 * @param {string} [props.description] - Descriptive text.
 *
 * @returns {React.ReactElement} - The visual component for a Block's "processing" state. (E.g., what gets displayed when the file is being processed api-side).
 */
export function DropAreaPending({ fileType, description }) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width="100%"
    >
      <Box width="50%" mb={2}>
        <LinearProgress />
      </Box>
      <Typography color="textSecondary">
        {description ??
          `${
            fileType ? `The ${fileType} content` : 'Content'
          } is processing and will be ready momentarily...`}
      </Typography>
    </Box>
  )
}

DropAreaPending.propTypes = {
  /**
   * Type of file to be uploaded. (i.e. 'image', 'audio', 'video')
   * This is used in the text to describe specific file to upload.
   */
  fileType: PropTypes.string,
  description: PropTypes.string,
}

DropAreaPending.defaultProps = {
  fileType: '',
}
