/**
 * @module YouTubeVideoBlock
 */
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  Box,
  Collapse,
  Container,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import YouTubeIcon from '@mui/icons-material/YouTube'
import { BaseBlock } from 'components/blocks/base-block'
import { RoundedButton } from 'components/ui/buttons/rounded-button'
import { blockContentErrorProps } from 'constants/prop-types'

export const youtubeVideoStates = Object.freeze({
  ACCEPTED: 'accepted',
  PENDING: 'pending',
})

// How to Make a Responsive 100% Width YouTube iFrame Embed:
// https://www.h3xed.com/web-development/how-to-make-a-responsive-100-width-youtube-iframe-embed
const useStyles = makeStyles((theme) => ({
  hidden: {
    visibility: 'hidden',
  },
  videoContainer: {
    height: '0',
    margin: '0.5rem 0 0',
    paddingBottom: '56.25%',
    position: 'relative',
    width: '100%',
  },
  videoFrame: {
    height: '100%',
    left: '0',
    position: 'absolute',
    top: '0',
    width: '100%',
  },
  videoWrapper: {
    backgroundColor: ({ isAccepted }) => {
      if (!isAccepted) {
        return theme.palette.grey[200]
      }
      return 'transparent'
    },
    borderRadius: theme.spacing(1),
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 0,
    minWidth: '100%',
    padding: theme.spacing(2),
    position: 'relative',
  },
}))

/**
 * @typedef VideoObject
 * @property {string} [error] - An error message from the block to be displayed.
 * @property {string} youtube_video_id - The video's YouTube id.
 */

/**
 * YouTube Video Block Component.
 *
 * @param {object} props - The component props object.
 * @param {string} props.blockId - The block's id.
 * @param {string} [props.className] - Optional additional className string.
 * @param {VideoObject} props.content - The video block content object.
 * @param {object} props.dragHandleProps - Object provided by React Beautiful DND to create a compatible drag handle.
 * @param {boolean} props.isDragging - Provided by React Beautiful DND to indicate the block is being dragged.
 * @param {Function} props.onChange - The change handler.
 * @param {Function} props.onDelete - The delete handler.
 * @param {string} [props.inputPlaceholder] - Input placeholder.
 * @param {string} [props.label] - Component aria label.
 * @param {string} [props.addVideoButtonLabel] - Add video button label.
 * @param {string} [props.previewHeading] - Preview label.
 *
 * @returns {React.ReactElement} - The video block component.
 *
 * @example <YouTubeVideoBlock blockId={newId()} content={{ youtube_video_id: "Se7dJwvMzlA" }} />
 */
export function YouTubeVideoBlock({
  blockId,
  className,
  content,
  dragHandleProps,
  isDragging,
  onChange,
  onDelete,
  inputPlaceholder,
  label,
  addVideoButtonLabel,
  previewHeading,
}) {
  const [isLoaded, setIsLoaded] = React.useState(false)
  const { error, youtube_video_id } = content
  const [videoId, setVideoId] = React.useState(youtube_video_id || '')
  const [status, setStatus] = React.useState(
    youtube_video_id ? youtubeVideoStates.ACCEPTED : youtubeVideoStates.PENDING,
  )
  const classes = useStyles({
    isAccepted: Boolean(status === youtubeVideoStates.ACCEPTED),
  })

  // YouTube Video IDs are alphanumeric and may contain hyphens and underscores.
  const videoIdRegEx = /([^a-z0-9_-])/gi

  // Source for list of YouTube URLs, specifically the comment linking to the
  // source for this RegEx:
  // https://gist.github.com/rodrigoborgesdeoliveira/987683cfbfcc8d800192da1e73adc486#gistcomment-3174652
  const youtubeUrlRegEx = /(\/|%3D|v=)([0-9A-z-_]{11})([%#?&]|$)/gm

  function onYouTubeVideoIdUrlChange(event) {
    // Check to see if there is a video ID match from the YouTube
    // URL RegExp. If so, set newVideoId to it, otherwise, set to
    // the pure field value, and for both, run videoIdRegEx on it
    // to replace non-id characters. Note that for URL matches,
    // the decode is used in case of URLs that are encoded so
    // things like `%3D` are processed as `=`.
    const inputValue = event.target.value
    let newVideoId
    const urlVideoIdMatch = inputValue.match(youtubeUrlRegEx)?.[0]
    if (urlVideoIdMatch) {
      newVideoId = decodeURIComponent(urlVideoIdMatch).replace(videoIdRegEx, '')
    } else {
      newVideoId = inputValue.replace(videoIdRegEx, '')
    }
    // Get last 11 characters (length of YouTube Video IDs), just
    // as a failsafe for extra characters being picked up in the
    // RegEx check.
    setVideoId(newVideoId.substr(newVideoId.length - 11))
    if (!newVideoId) {
      setIsLoaded(false)
    }
    onChange(blockId, {
      content: { youtube_video_id: newVideoId },
    })
  }

  function YouTubeFrame() {
    return (
      <Collapse in={Boolean(videoId && isLoaded)}>
        {videoId ? (
          <Container className={classes.videoContainer}>
            {!isLoaded ? (
              <Skeleton className={classes.videoFrame} variant="rectangular" />
            ) : null}
            <iframe
              allow="autoplay; encrypted-media"
              allowFullScreen={true}
              className={clsx(classes.videoFrame, !isLoaded && classes.hidden)}
              frameBorder="0"
              onLoad={() => setIsLoaded(true)}
              src={`https://www.youtube.com/embed/${videoId}?rel=0`}
            ></iframe>
          </Container>
        ) : null}
      </Collapse>
    )
  }

  return (
    <BaseBlock
      className={clsx('MUI-Block-Editor_block-youtube-video', className)}
      dragHandleProps={dragHandleProps}
      isDragging={isDragging}
      onDelete={onDelete}
    >
      <Box className={classes.videoWrapper}>
        {status !== youtubeVideoStates.ACCEPTED ? (
          <>
            <Box alignItems="center" display="flex">
              <Box flexGrow={1} mr={2}>
                <TextField
                  error={error}
                  fullWidth={true}
                  InputProps={{
                    'aria-label': label ?? 'YouTube Video ID or URL',
                    startAdornment: (
                      <InputAdornment position="start">
                        <YouTubeIcon />
                      </InputAdornment>
                    ),
                  }}
                  name="youtube_video_id_url"
                  onChange={onYouTubeVideoIdUrlChange}
                  placeholder={
                    inputPlaceholder ?? 'Enter YouTube Video ID or URL'
                  }
                  type="text"
                  variant="outlined"
                />
                <input
                  aria-label={label ?? 'YouTube Video ID'}
                  name="youtube_video_id"
                  type="hidden"
                  value={videoId}
                ></input>
              </Box>
              <Box>
                <RoundedButton
                  color="primary"
                  disabled={Boolean(!isLoaded && !videoId)}
                  onClick={() => {
                    setStatus(youtubeVideoStates.ACCEPTED)
                  }}
                  variant="contained"
                >
                  {addVideoButtonLabel ?? 'Add Video'}
                </RoundedButton>
              </Box>
            </Box>
            {videoId ? (
              <Box marginTop={3}>
                <Typography variant="h5">
                  {previewHeading ?? 'Preview'}
                </Typography>
                <YouTubeFrame />
              </Box>
            ) : null}
          </>
        ) : (
          <YouTubeFrame />
        )}
      </Box>
    </BaseBlock>
  )
}

YouTubeVideoBlock.propTypes = {
  blockId: PropTypes.string.isRequired,
  className: PropTypes.string,
  content: PropTypes.shape({
    error: PropTypes.shape(blockContentErrorProps),
    youtube_video_id: PropTypes.string.isRequired,
  }),
  dragHandleProps: PropTypes.object.isRequired,
  isDragging: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  label: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  addVideoButtonLabel: PropTypes.string,
  previewHeading: PropTypes.string,
}

YouTubeVideoBlock.defaultProps = {
  className: null,
}
