/**
 * @module Alerts
 */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ConditionalSnackbar } from '../conditional-snackbar' // fixes dependency cycle
import { useAlert } from './alert-context'

export * from './alert-context'

const useStyles = makeStyles((theme) => ({
  alertItem: {
    margin: theme.spacing(1),
  },
}))

/**
 * List of alerts.
 *
 * @alias module:Alerts
 *
 * @param {object} props - The component props object.
 * @param {boolean} [props.snackbar] - (Optional) Wrap alerts in a snackbar.
 * @param {object} [props.snackbarProps] - (Optional) Snackbar props. Requires `snackbar={true}`.
 * @param {string} [props.variant] - (Optional) An alert variant.
 *
 * @returns {React.ReactElement} - The alerts list.
 */
export function Alerts({ snackbar, snackbarProps, variant, ...props }) {
  const classes = useStyles()
  const { alerts: items, handleDismissAlert } = useAlert()
  return (
    <>
      {items.map((item, index) => {
        return (
          <>
            <ConditionalSnackbar
              enabled={snackbar}
              open={Boolean(item)}
              {...snackbarProps}
            >
              <Alert
                className={classes.alertItem}
                variant={variant}
                {...props}
                key={item.id}
                onClose={
                  item.onDismiss
                    ? () => item.onDismiss(index)
                    : () => handleDismissAlert(index)
                }
                severity={item.type}
              >
                {item.message}
              </Alert>
            </ConditionalSnackbar>
          </>
        )
      })}
    </>
  )
}
Alerts.propTypes = {
  snackbar: PropTypes.bool,
  snackbarProps: PropTypes.objectOf(PropTypes.any),
  variant: PropTypes.string,
}
Alerts.defaultProps = {
  snackbar: false,
  snackbarProps: {},
  variant: 'filled',
}
