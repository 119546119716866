/**
 * @module createReactDangerousMarkup
 */
/**
 * Creates the necessary object React's `dangerouslySetInnerHTML` needs.
 *
 * @alias module:createReactDangerousMarkup
 *
 * @param {string} html - HTML content.
 * @returns {object} Object fit for `dangerouslySetInnerHTML`.
 */
export function createReactDangerousMarkup(html) {
  if (!html) return null

  return { __html: html }
}
