/**
 * @module BlockEditor
 */
import React from 'react'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { AddBlock } from 'components/ui'
import {
  BlockEditorContextProvider,
  useBlocks,
} from 'context/block-editor-context'
import { blockEditorContextProps } from 'context/block-editor-context-props'

const useStyles = makeStyles((theme) => ({
  addBlock: {
    opacity: 1,
    transition: `opacity ${theme.transitions.duration.enteringScreen}ms ${theme.transitions.easing.easeInOut}`,
  },
  hidden: {
    opacity: 0,
  },
}))

/**
 * The block editor.
 *
 * @returns {React.ReactElement} - The block editor.
 */
function BlockEditorContainer() {
  const { blocks, config, deleteBlock, moveBlock, updateBlock } = useBlocks()
  const hasReadonlyBlock = Boolean(
    blocks.length && blocks[0].type === config.blockTypes.readonly?.name,
  )
  const onlyBlockIsReadonly = Boolean(hasReadonlyBlock && blocks.length === 1)
  const classes = useStyles()

  function handleBlockRearrange({ source, destination }) {
    if (!source || !destination) return

    if (
      typeof source.index === 'number' &&
      typeof destination.index === 'number'
    ) {
      moveBlock(source.index, destination.index)
    }
  }

  let readonlyBlock = null
  if (hasReadonlyBlock) {
    const { Component: ReadonlyComponent } = config.blockTypes.readonly
    readonlyBlock = (
      <>
        <ReadonlyComponent
          content={blocks[0].content}
          disableDelete={blocks.length === 1}
          disableDrag={true}
          dragHandleProps={{}}
          isDragging={false}
          onChange={() => {}}
          onDelete={() => deleteBlock(blocks[0].block_id)}
        />
      </>
    )
  }

  return (
    <>
      {readonlyBlock}

      <DragDropContext onDragEnd={handleBlockRearrange} useDragHandle={true}>
        <Droppable droppableId="block-editor-droppable">
          {(droppableProvided, droppableSnapshot) => (
            <div
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
            >
              {!blocks.length || onlyBlockIsReadonly ? (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <AddBlock
                    initialOpen={!blocks.length}
                    isMainAddBlock={true}
                  />
                </Box>
              ) : (
                <div
                  className={clsx([
                    classes.addBlock,
                    droppableSnapshot.isDraggingOver && classes.hidden,
                  ])}
                >
                  <AddBlock
                    disabled={droppableSnapshot.isDraggingOver}
                    insertAtIndex={0}
                  />
                </div>
              )}

              {blocks?.map((block, index) => {
                const { Component: BlockChildren } = config.blockTypes[
                  block.type
                ]

                if (block.type === config.blockTypes.readonly?.name) {
                  return null
                }

                return (
                  <Draggable
                    draggableId={block.block_id}
                    key={`block_${block.block_id}`}
                    direction="vertical"
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...provided.draggableProps}
                      >
                        <BlockChildren
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...(config?.blockTypes[block.type]
                            ? config?.blockTypes[block.type]
                            : {})}
                          blockId={block.block_id}
                          content={block.content}
                          dragHandleProps={provided.dragHandleProps}
                          isDragging={droppableSnapshot.isDraggingOver}
                          onChange={updateBlock}
                          onDelete={() => deleteBlock(block.block_id)}
                          muiIcon={config.blockTypes[block.type].Icon}
                          fileType={config.blockTypes[block.type].name}
                        />
                        <div
                          className={clsx([
                            classes.addBlock,
                            droppableSnapshot.isDraggingOver && classes.hidden,
                          ])}
                        >
                          <AddBlock
                            disabled={droppableSnapshot.isDraggingOver}
                            insertAtIndex={index + 1}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}

/**
 * The contextualized block editor container.
 *
 * @param {object} props - The props object.
 * @param {Array} props.blocks - The block array.
 * @param {object} [props.config] - An optional configuration object.
 * @param {Function} props.dispatch - The function to update blocks state.
 *
 * @returns {React.ReactElement} - The block editor container.
 *
 * @example
 * <BlockEditor
 *   blocks={[
 *     {block_id: '1234abc', type: 'text', content: { html: '<p>Some random stuff</p>' }},
 *     {block_id: '4567jkl', type: 'video', content: { youtube_video_id: 'Se7dJwvMzlA' }}
 *   ]}
 *   config={{
 *     blockTypes: {
 *       audio: {
 *         dropAreaDownloadText: 'Download this file.',
 *         dropAreaEmptyText: 'Only MP3s are allowed.',
 *       },
 *     },
 *     handleFileUpload: () => {},
 *   }}
 *   dispatch={customDispatchFunction}
 * />
 */
export function BlockEditor({ blocks, config, dispatch }) {
  return (
    <BlockEditorContextProvider
      blocks={blocks}
      config={config}
      dispatch={dispatch}
    >
      <BlockEditorContainer />
    </BlockEditorContextProvider>
  )
}

BlockEditor.propTypes = blockEditorContextProps.propTypes
BlockEditor.defaultProps = blockEditorContextProps.defaultProps
