/**
 * @module DropAreaDragActive
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Fade } from '@mui/material'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'

/**
 * Display this component while the Drop Area is in the "drag active" state (a
 * state provided by the Drop Area hook).
 *
 * @alias module:DropAreaDragActive
 *
 * @param {object} props - Component props object.
 * @param {boolean} [props.isDragReject] - Indicates if the file being dragged over is invalid.
 *
 * @returns {React.ReactElement} - The visual component for the Drop Area's "drag active" state.
 */
export function DropAreaDragActive({ isDragReject }) {
  return (
    <Fade in={true}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        {isDragReject ? (
          <SentimentDissatisfiedIcon fontSize="large" color="action" />
        ) : (
          <InsertEmoticonIcon fontSize="large" color="action" />
        )}
        <Box mt={2}>
          <Typography color="textSecondary">
            {isDragReject
              ? 'That file is not supported'
              : `Drop the file here to upload`}
          </Typography>
        </Box>
      </Box>
    </Fade>
  )
}

DropAreaDragActive.propTypes = {
  isDragReject: PropTypes.bool.isRequired,
}
