/**
 * @module EmptyState
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Box, Container, Typography } from '@mui/material'

/**
 * Empty State component.
 * Use this component when content is unavailable to display on any given page. The `headerImage` and `muiHeaderIcon` props are mutually exclusive.
 *
 * @alias module:EmptyState
 *
 * @param {object} props - The component props.
 * @param {React.ReactElement} [props.actionButton] - The component used to take action, such as a CTA button.
 * @param {React.ReactElement} [props.headerImage] - Image element to be displayed above the `headerText` and `subtitleText`. It's recommended to utilize our LazyImage component. If defined, you cannot also define a `muiHeaderIcon`.
 * @param {string} props.headerText - The header text providing the main point.
 * @param {('xs'|'sm'|'md'|'lg')} [props.maxWidth] - {@link https://material-ui.com/components/container/#container Material-UI Container} Component's maxWidth. Defaults to 'sm'.
 * @param {React.ReactElement} [props.muiHeaderIcon] - Material-UI icon element to be displayed above the `headerText` and `subtitleText`. If defined, you cannot also define a `headerImage`.
 * @param {string} [props.subtitleText] - The subtitle text used to describe the main point or point the user towards an action.
 *
 * @throws {Error} - Throws error when `headerImageUrl` and `muiHeaderIcon` are both defined.
 *
 * @returns {React.ReactElement} - The EmptyState component.
 *
 * @example
 * import React from 'react'
 * import { EmptyState } from '@youversion/react'
 * import ShowChartIcon from '@mui/icons-material/ShowChart'
 *
 * function MyComponent() {
 *   return (
 *    <EmptyState
 *       actionButton={(
 *         <Button
 *           color="primary"
 *           onClick={() => {}}
 *           variant="contained"
 *         >
 *           Add a Podcast
 *         </Button>
 *       )}
 *       headerText="To see Analytics, Add a Podcast"
 *       muiHeaderIcon={<ShowChartIcon />}
 *       subtitleText="When available, Podcast analytics display in this space."
 *    />
 *   )
 * }
 */
export function EmptyState({
  actionButton,
  headerImage,
  headerText,
  maxWidth,
  muiHeaderIcon,
  subtitleText,
}) {
  if (headerImage && muiHeaderIcon) {
    throw new Error(
      '[EmptyState] The `headerImage` and `muiHeaderIcon` props are mutually exclusive.',
    )
  }

  // Adds optimal font size for Material-UI icons.
  const muiIcon = muiHeaderIcon
    ? React.cloneElement(muiHeaderIcon, {
        'aria-hidden': 'false',
        'aria-label': muiHeaderIcon?.type.type.render.displayName || '',
        fontSize: 'inherit',
        role: 'img',
      })
    : null

  return (
    <Container maxWidth={maxWidth}>
      {headerImage ? (
        <Box display="flex" justifyContent="center">
          <Box clone={true} maxWidth="100%" mb={2}>
            {headerImage}
          </Box>
        </Box>
      ) : null}

      {muiIcon ? (
        <Box display="flex" fontSize={80} justifyContent="center" width="100%">
          {muiIcon}
        </Box>
      ) : null}

      <Typography
        align="center"
        color="textPrimary"
        gutterBottom={true}
        variant="h3"
      >
        {headerText}
      </Typography>

      {subtitleText ? (
        <Typography align="center" color="textSecondary" variant="body1">
          {subtitleText}
        </Typography>
      ) : null}

      {actionButton ? (
        <Box display="flex" justifyContent="center" mt={4}>
          {actionButton}
        </Box>
      ) : null}
    </Container>
  )
}

EmptyState.propTypes = {
  /**
   * The component used to take action, such as a CTA button.
   */
  actionButton: PropTypes.element,
  /**
   * Header image element to be displayed above the `headerText` and `subtitleText`.
   * If defined, you cannot also define a `muiHeaderIcon`.
   */
  headerImage: PropTypes.element,
  /**
   * The header text providing the main point.
   */
  headerText: PropTypes.string.isRequired,
  /**
   * Material-UI Container component's maxWidth.
   */
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  /**
   * Material-UI icon element to be displayed above the `headerText` and `subtitleText`.
   * If defined, you cannot also define a `headerImage`.
   */
  muiHeaderIcon: PropTypes.element,
  /**
   * The subtitle text used to describe the main point or point the user towards an action.
   */
  subtitleText: PropTypes.string,
}

EmptyState.defaultProps = {
  actionButton: null,
  headerImage: null,
  maxWidth: 'sm',
  muiHeaderIcon: null,
  subtitleText: '',
}
