/* eslint-disable jsdoc/require-file-overview */
/**
 * Do not `import { somePropType } from 'constants' directly inside of this package.
 * Node will try to pick it up from a deprecated 'constants' package.
 * Instead, `import { somePropType } from 'constants/prop-types'`.
 *
 * For external use, `import { somePropType } from '@youversion/mui-block-editor/constants'` is valid.
 */
export * from './action-types'
export * from './default-block-types'
export * from './prop-types'
