/**
 * @module BaseBlock
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Box, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import clsx from 'clsx'
import { ConfirmDialog } from 'components/ui/dialogs'
import { useBlocks } from 'context'

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.MUI-Block-Editor_block-root:not(:hover)': {
      '& .block-icon-delete': {
        opacity: 0,
        pointerEvents: 'none',
      },
    },
    '.MUI-Block-Editor_block-root:not(:hover):not(:active)': {
      '& .block-drag-handle': {
        opacity: 0,
        pointerEvents: 'none',
      },
    },
  },
  dragHandle: {
    cursor: 'move',
    userSelect: 'none',
  },
  hidden: {
    visibility: 'hidden',
  },
  icon: {
    transition: `opacity ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
  },
}))

/**
 * The drag handle component.
 *
 * @param {object} props - Props object.
 * @param {string} [props.className] - Optional additional className string.
 * @param {boolean} [props.disableDrag] - Optionally disable the drag IconButton.
 * @param {object} [props.dragHandleProps] - Object provided by React Beautiful DND to create a compatible drag handle.
 * @param {object} [props.label] - Action label.
 *
 * @returns {React.ReactElement} - The drag handle component.
 */
const DragHandle = ({ className, disableDrag, dragHandleProps, label }) => {
  const classes = useStyles()

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...dragHandleProps}
      className={clsx(className, 'block-drag-handle')}
    >
      <IconButton
        aria-label={label ?? 'Move this block'}
        className={clsx(classes.dragHandle, disableDrag ? classes.hidden : '')}
        disabled={disableDrag}
      >
        <DragIndicatorIcon />
      </IconButton>
    </div>
  )
}

DragHandle.propTypes = {
  className: PropTypes.string,
  disableDrag: PropTypes.bool,
  dragHandleProps: PropTypes.object,
  label: PropTypes.string,
}

DragHandle.defaultProps = {
  className: '',
  disableDrag: false,
  dragHandleProps: {},
}

/**
 * The BaseBlock upon which all other blocks are built.
 *
 * @param {object} props - The component props object.
 * @param {boolean} props.active - Optional active state.
 * @param {React.ReactChildren} props.children - React children.
 * @param {string} props.className - Optional additional className string.
 * @param {boolean} props.disableDelete - Optionally disable ability to delete block.
 * @param {boolean} props.disableDrag - Optionally disable ability to drag block.
 * @param {object} [props.dragHandleProps] - Object provided by React Beautiful DND to create a compatible drag handle.
 * @param {boolean} props.isDragging - Provided by React Beautiful DND to indicate the block is being dragged.
 * @param {Function} props.onDelete - The delete handler.
 *
 * @returns {React.ReactElement} - The BaseBlock component.
 *
 * @example
 * return (
 * <BaseBlock onDelete={() => deleteBlock(blockId)}>
 * <div className="video-block">A video block</div>
 * </BaseBlock>
 * )
 */
export function BaseBlock({
  active,
  children,
  className,
  disableDelete,
  disableDrag,
  dragHandleProps,
  isDragging,
  onDelete,
  ...props
}) {
  const classes = useStyles()
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const { config } = useBlocks()

  return (
    <>
      <Box
        display="flex"
        className={clsx('MUI-Block-Editor_block-root', className)}
      >
        <Box>
          <DragHandle
            disableDrag={disableDrag}
            dragHandleProps={dragHandleProps}
            className={classes.icon}
            label={config?.strings?.moveBlockLabel}
          />
        </Box>
        <Box
          flexGrow={1}
          py={0}
          px={1.5}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        >
          {children}
        </Box>
        <Box>
          <IconButton
            aria-label={
              config?.strings?.deleteBlockLabel ?? 'Delete this block'
            }
            className={clsx(
              classes.icon,
              'block-icon-delete',
              disableDelete ? classes.hidden : '',
            )}
            onClick={() => setDialogOpen(true)}
            disabled={isDragging}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
      </Box>
      <ConfirmDialog
        confirmButtonLabel={
          config?.strings?.confirmDeleteBlockButtonLabel ?? 'Delete'
        }
        content={
          config?.strings?.confirmDeleteBlockContent ??
          'Are you sure you want to delete this section?'
        }
        denyButtonLabel={
          config?.strings?.denyDeleteBlockButtonLabel ?? 'Cancel'
        }
        onClose={() => setDialogOpen(false)}
        onConfirm={() => {
          setDialogOpen(false)
          onDelete()
        }}
        onDeny={() => setDialogOpen(false)}
        open={dialogOpen}
      />
    </>
  )
}

BaseBlock.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disableDelete: PropTypes.bool,
  disableDrag: PropTypes.bool,
  dragHandleProps: PropTypes.object,
  isDragging: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
}

BaseBlock.defaultProps = {
  active: false,
  className: null,
  disableDelete: false,
  disableDrag: false,
  dragHandleProps: {},
}
