/**
 * @module SerializeImage
 */

/**
 * @typedef ImageObject
 * @property {string} [alt] - The image's text description.
 * @property {string} [display_url] - An image URL string for the img `src` attribute.
 */

/**
 * Serialize the provided image content.
 *
 * @alias module:serializeImage
 *
 * @param {ImageObject} content - The content to serialize.
 *
 * @returns {(string|null)} The serialized image content as HTML, or null.
 */
// eslint-disable-next-line no-empty-pattern
export function serializeImage(content) {
  const { alt, display_url } = content

  if (!display_url) {
    return null
  }

  return `<img src="${display_url}"${
    alt ? ` alt="${alt}"` : ''
  } width="100%" />`
}
