/**
 * @module VideoBlock
 */
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Container, Skeleton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { BaseBlock } from 'components/blocks/base-block'
import { FileDropArea, fileDropAreaStates } from 'components/blocks/drop-area'
import {
  blockContentErrorProps,
  dropAreaProps,
  dropAreaDefaultProps,
} from 'constants/prop-types'
// import { serializeVideo } from 'serializers/video-serializer'
// import { createReactDangerousMarkup } from 'utils/create-react-dangerous-markup'

// How to Make a Responsive 100% Width YouTube iFrame Embed:
// https://www.h3xed.com/web-development/how-to-make-a-responsive-100-width-youtube-iframe-embed
const useStyles = makeStyles(() => ({
  hidden: {
    visibility: 'hidden',
  },
  videoContainer: {
    height: '0',
    paddingBottom: '56.25%',
    position: 'relative',
    width: '100%',
  },
  videoFrame: {
    height: '100%',
    left: '0',
    position: 'absolute',
    top: '0',
    width: '100%',
  },
}))

/**
 * @typedef VideoObject
 * @property {string} [error] - An error message from the block to be displayed.
 * @property {string} file_id - The video file upload status.
 * @property {string} source_url - The original source file's url.
 * @property {string} youtube_video_id - The video's YouTube id.
 */

/**
 * Video Block Component.
 *
 * @param {object} props - The component props object.
 * @param {string} props.blockId - The block's id.
 * @param {string} props.className - Optional additional className string.
 * @param {VideoObject} props.content - The video block content object.
 * @param {string|React.ReactElement} [props.dropAreaDownloadText] - The drop area download state descriptive text.
 * @param {string} [props.dropAreaDownloadButtonLabel] - The drop area download state button label.
 * @param {string|React.ReactElement} [props.dropAreaEmptyText] - The drop area empty state descriptive text.
 * @param {string} [props.dropAreaEmptyUploadButtonLabel] - The drop area empty state upload button label.
 * @param {string} [props.dropAreaActiveFileNotSupportedText] - The drop area active state file not supported text.
 * @param {string} [props.dropAreaActiveUploadText] - The drop area active state upload text.
 * @param {string} [props.dropAreaErrorRetryLabel] - The drop area error state retry label.
 * @param {string} [props.dropAreaUploadingText] - The drop area uploading state descriptive text.
 * @param {string} [props.dropAreaPendingText] - The drop area pending state descriptive text.
 * @param {object} props.dragHandleProps - Object provided by React Beautiful DND to create a compatible drag handle.
 * @param {boolean} props.isDragging - Provided by React Beautiful DND to indicate the block is being dragged.
 * @param {React.ReactElement} props.muiIcon - Material UI icon to display in the file drop area.
 * @param {Function} props.onDelete - The delete handler.
 *
 * @returns {React.ReactElement} - The video block component.
 *
 * @example <VideoBlock blockId={newId()} content={{ youtube_video_id: "Se7dJwvMzlA" }} />
 */
export function VideoBlock({
  blockId,
  className,
  content,
  dropAreaDownloadText,
  dropAreaDownloadButtonLabel,
  dropAreaEmptyText,
  dropAreaEmptyUploadButtonLabel,
  dropAreaActiveFileNotSupportedText,
  dropAreaActiveUploadText,
  dropAreaErrorRetryLabel,
  dropAreaPendingText,
  dropAreaUploadingText,
  dragHandleProps,
  isDragging,
  muiIcon,
  onDelete,
}) {
  const [isLoaded, setIsLoaded] = React.useState(false)
  const classes = useStyles()
  const { error, source_url, youtube_video_id } = content
  const isFilePreviewable = Boolean(!error && youtube_video_id)

  // Normally we'd use the video serializer to get the HTML for the Video Block.
  // Because we need to know when the video is loading, I found it easier to just
  // use the JSX version of it.
  const VideoBlockPreview = (
    <Container className={classes.videoContainer}>
      {!isLoaded ? (
        <Skeleton className={classes.videoFrame} variant="rectangular" />
      ) : null}
      <iframe
        allow="autoplay; encrypted-media"
        allowFullScreen={true}
        className={clsx(classes.videoFrame, !isLoaded && classes.hidden)}
        frameBorder="0"
        src={`https://www.youtube.com/embed/${youtube_video_id}?rel=0`}
        onLoad={() => setIsLoaded(true)}
      ></iframe>
    </Container>
  )

  const FileUploader = (
    <FileDropArea
      acceptedFileTypes="video/*"
      blockId={blockId}
      dropAreaDownloadText={dropAreaDownloadText}
      dropAreaDownloadButtonLabel={dropAreaDownloadButtonLabel}
      dropAreaEmptyText={dropAreaEmptyText}
      dropAreaEmptyUploadButtonLabel={dropAreaEmptyUploadButtonLabel}
      dropAreaErrorRetryLabel={dropAreaErrorRetryLabel}
      dropAreaPendingText={dropAreaPendingText}
      dropAreaUploadingText={dropAreaUploadingText}
      error={error}
      fileSourceUrl={source_url}
      fileType="video"
      muiIcon={muiIcon}
      uploadStatusOverride={
        source_url ? fileDropAreaStates.DOWNLOAD_FILE : null
      }
      dropAreaActiveFileNotSupportedText={dropAreaActiveFileNotSupportedText}
      dropAreaActiveUploadText={dropAreaActiveUploadText}
    />
  )

  // Not using the serialized HTML for the video block in favor of the above solution.
  // const serializedVideoBlock = (
  //   <div
  //     dangerouslySetInnerHTML={createReactDangerousMarkup(
  //       serializeVideo(content),
  //     )}
  //   />
  // )

  return (
    <BaseBlock
      className={clsx('MUI-Block-Editor_block-video', className)}
      dragHandleProps={dragHandleProps}
      isDragging={isDragging}
      onDelete={onDelete}
    >
      {isFilePreviewable ? VideoBlockPreview : FileUploader}
    </BaseBlock>
  )
}

VideoBlock.propTypes = {
  blockId: PropTypes.string.isRequired,
  className: PropTypes.string,
  content: PropTypes.shape({
    error: PropTypes.shape(blockContentErrorProps),
    file_id: PropTypes.string.isRequired,
    source_url: PropTypes.string.isRequired,
    youtube_video_id: PropTypes.string.isRequired,
  }),
  dragHandleProps: PropTypes.object.isRequired,
  isDragging: PropTypes.bool.isRequired,
  muiIcon: PropTypes.elementType,
  onDelete: PropTypes.func.isRequired,
  ...dropAreaProps,
}

VideoBlock.defaultProps = {
  className: null,
  ...dropAreaDefaultProps,
}
