/**
 * @module mockApiCall
 */
/**
 * Simple function to mock an api call with options to control timing and status.
 *
 * @alias module:mockApiCall
 *
 * @param {object} props - The function props.
 * @param {object} [props.responseObj] - The object response once the mock API call resolves.
 * @param {number} [props.timeout] - Duration of time in milliseconds for the mock API call.
 * @param {boolean} [props.shouldReject] - Manually indicate if the mock API call will reject.
 * @param {Function} [props.resolveFunction] - Optional function to be run after timeout and before promise resolves.
 *
 * @returns {object|Error} If mock API call resolves, it will return the responseObj prop; otherwise it will throw an error.
 */
export async function mockApiCall({
  responseObj = {},
  timeout = 1000,
  shouldReject = false,
  resolveFunction,
}) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (shouldReject) reject(responseObj)

      if (typeof resolveFunction === 'function') {
        resolveFunction()
      }

      resolve(responseObj)
    }, timeout)
  })
}
