/** @module blockEditorContextProps */
import PropTypes from 'prop-types'
import { blocksProps, configProps } from 'constants/prop-types'

export const blockEditorContextProps = {
  defaultProps: {
    config: {
      blocks: {},
    },
  },
  propTypes: {
    blocks: blocksProps,
    config: configProps,
    dispatch: PropTypes.func.isRequired,
  },
}
