/**
 * @module serializeText
 */
import { ContentState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import sanitizeHtml from 'sanitize-html'

function cleanUpHtml(html) {
  return sanitizeHtml(html, {
    allowedAttributes: {
      a: ['href'],
      p: ['style'],
      span: ['style'],
    },
    allowedStyles: {
      '*': {
        'font-style': [/^italic$/i],
        'font-weight': [/^700$/i, /^bold$/i, /^bolder$/i],
        'text-decoration': [/^underline$/i],
      },
    },
    allowedTags: [
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'blockquote',
      'li',
      'ol',
      'p',
      'ul',
      'a',
      'em',
      'span',
      'i',
      'strong',
      'br',
      'div',
    ],
  })
}

function stripUnsupportedHtml(html) {
  if (!html) {
    return ''
  }

  const sanitizedHtml = cleanUpHtml(html)

  // This finds the unnecessary 'p' tag wrapping a 'br' tag.
  const matchEditorLineBreaks = new RegExp(
    `\\s*<p>\\s*(<br(\\s)?\\/?>\\s?)+<\\/p>\\s*`,
    'g',
  )
  const matchNonBreakingSpace = new RegExp('&nbsp;', 'g')
  const matchMultipleSpaces = new RegExp('\\s+', 'g')
  const matchEmptyTags = new RegExp('<([a-z]+)>\\s*<\\/\\1>', 'g')

  return sanitizedHtml
    .replace(matchNonBreakingSpace, ' ')
    .replace(matchMultipleSpaces, ' ')
    .replace(matchEmptyTags, '')
    .replace(matchEditorLineBreaks, '')
}

/**
 * Returns serialized TextBlock HTML or `null`.
 *
 * @alias module:serializeText
 *
 * @param {object} content - The content to serialize.
 * @param {object} [params] - Optional params object.
 *
 * @returns {string} The serialized text content.
 */
// eslint-disable-next-line no-empty-pattern
export function serializeText(content, {} = {}) {
  if (content instanceof ContentState) {
    const html = stateToHTML(content, {
      blockRenderers: {
        RawHTMLBlock: (block) => block.getText(),
      },
    })
    return stripUnsupportedHtml(html)
  }

  return stripUnsupportedHtml(content.html)
}
