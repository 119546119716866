/**
 * @module DropAreaFailed
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button as MuiButton, Typography } from '@mui/material'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'

/**
 * Display this component if the drop area has encountered some kind of error.
 *
 * @alias module:DropAreaFailed
 *
 * @param {object} props - Component props object.
 * @param {React.ReactElement} [props.button] - An optional button component.
 * @param {boolean} [props.disableRetry] - Disable the retry functionality.
 * @param {string} [props.heading] - Custom error heading to display to end users.
 * @param {string} [props.message] - Custom error message to display to end users.
 *
 * @returns {React.ReactElement} - The visual component for the drop area's "error" state.
 */
export function DropAreaFailed({
  button: Button,
  disableRetry,
  heading,
  message,
}) {
  return (
    <>
      <SentimentDissatisfiedIcon fontSize="large" color="action" />
      <Box mt={1} mb={2}>
        <Typography color="textSecondary" variant="h6" gutterBottom={true}>
          {heading}
        </Typography>
        <Typography color="textSecondary">{message}</Typography>
      </Box>
      {!disableRetry ? (
        <Button color="primary" variant="outlined">
          Try Again
        </Button>
      ) : null}
    </>
  )
}
DropAreaFailed.propTypes = {
  button: PropTypes.elementType,
  disableRetry: PropTypes.bool,
  heading: PropTypes.node,
  message: PropTypes.node,
}
DropAreaFailed.defaultProps = {
  button: MuiButton,
  disableRetry: false,
  heading: 'Failed to upload',
  message: 'An unexpected error occurred.',
}
