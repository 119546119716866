/**
 * @module SerializeBlocks
 */

/**
 * Serialize the contents of the specified blocks.
 *
 * @alias module:serializeBlocks
 *
 * @param {Array} blocks - The array of blocks to serialize.
 * @param {object} supportedBlocks - An object of supported blocks, typically `config.blockTypes`.
 * @param {object} [params] - Optional params object.
 *
 * @returns {string} The serialized blocks data.
 */
// eslint-disable-next-line no-empty-pattern
export function serializeBlocks(blocks, supportedBlocks, {} = {}) {
  let serializedData = ''
  // eslint-disable-next-line consistent-return
  blocks.forEach((block) => {
    const options = {}
    switch (block.type) {
      case supportedBlocks.audio?.name:
        // Update `options` as needed.
        break
      case supportedBlocks.image?.name:
        // Update `options` as needed.
        break
      case supportedBlocks.readonly?.name:
        // Update `options` as needed.
        break
      case supportedBlocks.soundcloud_audio?.name:
        // Update `options` as needed.
        break
      case supportedBlocks.text?.name:
        // Update `options` as needed.
        break
      case supportedBlocks.video?.name:
        // Update `options` as needed.
        break
      case supportedBlocks.youtube_video?.name:
        // Update `options` as needed.
        break
      default:
        return new Error(
          `Unsupported block type "${block.type}" found on block ${block}.`,
        )
    }
    serializedData += supportedBlocks[block.type].serialize(
      block.content,
      options,
    )
  })
  return serializedData
}
