/**
 * @module FileDropArea
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useDropzone } from 'react-dropzone'
import { useBlocks } from 'context/block-editor-context'
import {
  blockContentErrorProps,
  dropAreaDefaultProps,
  dropAreaProps,
} from 'constants/prop-types'
import {
  DropAreaDownloadFile,
  DropAreaDragActive,
  DropAreaEmpty,
  DropAreaFailed,
  DropAreaPending,
  DropAreaUploading,
} from './shared-states'

const useStyles = makeStyles((theme) => ({
  dropArea: {
    alignItems: 'center',
    backgroundColor: ({ isDragActive, isDragReject }) => {
      if (isDragReject) {
        return `${theme.palette.warning.main}15`
      }

      if (isDragActive) {
        return `${theme.palette.primary.main}25`
      }

      return theme.palette.grey[200]
    },
    border: `2px dashed ${theme.palette.grey[200]}`,
    borderColor: ({ isDragActive, isDragReject }) => {
      if (isDragReject) {
        return theme.palette.warning.main
      }

      if (isDragActive) {
        return theme.palette.primary.main
      }

      return theme.palette.grey[200]
    },
    borderRadius: theme.spacing(1),
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 0,
    minHeight: '250px',
    minWidth: '100%',
    padding: theme.spacing(2),
    // eslint-disable-next-line sort-keys
    '&:focus': {
      outlineColor: theme.palette.primary.main,
    },
    position: 'relative',
  },
}))

export const fileDropAreaStates = Object.freeze({
  DOWNLOAD_FILE: 'download-file',
  DRAG_ACTIVE: 'drag-active',
  DRAG_REJECT: 'drag-reject',
  FAILED: 'failed',
  FINISHED: 'finished',
  IDLE: 'idle',
  PENDING: 'pending',
  PROCESSING: 'processing',
})

/**
 * @typedef ErrorObject
 * @property {boolean} [disableRetry] - An option to disable retrying the upload.
 * @property {React.ReactNode} [heading] - An optional header node.
 * @property {React.ReactNode} [message] - The error message node.
 */

/**
 * Display this component while your Block is empty and awaiting files
 * to be dropped or loaded.
 *
 * @param {object} props - Component props object.
 * @param {string} [props.acceptedFileTypes] - Expected file types in file upload controls. (https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#Unique_file_type_specifiers).
 * @param {string} props.blockId - The block id.
 * @param {string|React.ReactElement} [props.dropAreaDownloadText] - The drop area download state descriptive text.
 * @param {string} [props.dropAreaDownloadButtonLabel] - The drop area download state button label.
 * @param {string|React.ReactElement} [props.dropAreaEmptyText] - The drop area empty state descriptive text.
 * @param {string} [props.dropAreaEmptyUploadButtonLabel] - The drop area empty state upload button label.
 * @param {string} [props.dropAreaActiveFileNotSupportedText] - The drop area active state file not supported text.
 * @param {string} [props.dropAreaActiveUploadText] - The drop area active state upload text.
 * @param {string} [props.dropAreaErrorRetryLabel] - The drop area error state retry label.
 * @param {string} [props.dropAreaUploadingText] - The drop area uploading state descriptive text.
 * @param {string} [props.dropAreaPendingText] - The drop area pending state descriptive text.
 * @param {ErrorObject} [props.error] - A block content error to be displayed.
 * @param {string} [props.fileSourceUrl] - The source url of the file available to download.
 * @param {string} [props.fileType] - Type of file to be uploaded. (i.e. 'image', 'audio', 'video').
 * @param {boolean} [props.isContentProcessing] - Indicates if the content is processing api-side and will display a loading state until processed.
 * @param {React.ReactElement} [props.muiIcon] - Material UI icon to display in the file drop area. (https://material-ui.com/components/material-icons/).
 * @param {string} [props.uploadStatusOverride] - (Optional) Override the component's upload status manually.
 * @returns {React.ReactElement} - Component for drag and drop file uploading. Used in block editor.
 * @example <FileDropArea acceptedFileTypes="image/*" blockId="60b9b2f5-24a7-45e2-9495-533793a95072" fileType="image" muiIcon={CloudUploadIcon} />
 */
export function FileDropArea({
  acceptedFileTypes,
  blockId,
  dropAreaDownloadText,
  dropAreaDownloadButtonLabel,
  dropAreaEmptyText,
  dropAreaEmptyUploadButtonLabel,
  dropAreaActiveFileNotSupportedText,
  dropAreaActiveUploadText,
  dropAreaErrorRetryLabel,
  dropAreaPendingText,
  dropAreaUploadingText,
  error,
  fileSourceUrl,
  fileType,
  isContentProcessing,
  muiIcon,
  uploadStatusOverride,
}) {
  const [uploadStatus, setUploadStatus] = React.useState(
    fileDropAreaStates.IDLE,
  )
  const [uploadErrors, setUploadErrors] = React.useState({})
  const { config, updateBlock } = useBlocks()
  const { handleFileUpload } = config

  const onDrop = React.useCallback(
    async (acceptedFiles, fileRejections) => {
      setUploadStatus(fileDropAreaStates.PENDING)

      // if there's an error for any reason, display it...
      if (fileRejections.length) {
        setUploadErrors(() => {
          // doing it with an object versus array because I could sometimes
          // have the same error if multiple files were uploaded, so I'm
          // protecting duplicates with a key.
          const errorObj = {}
          fileRejections.forEach(({ errors: fileUploadErrors }) =>
            fileUploadErrors.forEach((uploadError) => {
              errorObj[uploadError.code] = uploadError.message
            }),
          )
          return errorObj
        })

        setUploadStatus(fileDropAreaStates.FAILED)
        return
      }

      try {
        // handleUpload comes from the consuming app, and needs to resolve `{file_id, source_url}`
        const response = await handleFileUpload(acceptedFiles[0])
        setUploadStatus(fileDropAreaStates.FINISHED)
        updateBlock(blockId, {
          contentParams: {
            ...response,
          },
        })
      } catch {
        setUploadStatus(fileDropAreaStates.FAILED)
      }
    },
    [blockId, handleFileUpload, updateBlock],
  )

  // Inversion of control
  let status = uploadStatus

  React.useEffect(() => {
    if (uploadStatusOverride || isContentProcessing || error) {
      setUploadStatus((previousStatus) => {
        // These upload statuses are in order of precedence. Do not re-order.
        if (error) {
          return fileDropAreaStates.FAILED
        }

        if (uploadStatusOverride) {
          return uploadStatusOverride
        }
        if (isContentProcessing) {
          return fileDropAreaStates.PROCESSING
        }
        return previousStatus
      })
    }
  }, [uploadStatusOverride, isContentProcessing, error])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    accept: acceptedFileTypes,
    disabled:
      isContentProcessing ||
      error?.disableRetry === true ||
      !(
        status === fileDropAreaStates.IDLE ||
        status === fileDropAreaStates.FAILED
      ),
    maxFiles: 1, // we can always allow this to be passed in as a prop, but for right now, we'll work with one.
    multiple: false, // disables drag and drop of multiple files
    onDrop,
  })

  if (isDragActive) {
    status = isDragReject
      ? fileDropAreaStates.DRAG_REJECT
      : fileDropAreaStates.DRAG_ACTIVE
  }

  const classes = useStyles({
    isDragActive: Boolean(status === fileDropAreaStates.DRAG_ACTIVE),
    isDragReject: Boolean(status === fileDropAreaStates.DRAG_REJECT),
  })

  function getDropAreaContent() {
    let dropAreaContent

    switch (status) {
      case fileDropAreaStates.IDLE:
        dropAreaContent = (
          <DropAreaEmpty
            description={dropAreaEmptyText}
            fileType={fileType}
            muiIcon={muiIcon}
            uploadButtonCustomLabel={dropAreaEmptyUploadButtonLabel}
          />
        )
        break
      case fileDropAreaStates.DRAG_REJECT:
      case fileDropAreaStates.DRAG_ACTIVE:
        dropAreaContent = (
          <DropAreaDragActive
            isDragReject={Boolean(status === fileDropAreaStates.DRAG_REJECT)}
            fileNotSupportedCustomText={dropAreaActiveFileNotSupportedText}
            dropFileToUploadCustomText={dropAreaActiveUploadText}
          />
        )
        break
      case fileDropAreaStates.DOWNLOAD_FILE:
        dropAreaContent = (
          <DropAreaDownloadFile
            description={dropAreaDownloadText}
            fileType={fileType}
            muiIcon={muiIcon}
            sourceUrl={fileSourceUrl}
            downloadButtonLabel={dropAreaDownloadButtonLabel}
          />
        )
        break
      case fileDropAreaStates.PENDING:
        dropAreaContent = (
          <DropAreaUploading
            fileType={fileType}
            description={dropAreaUploadingText}
          />
        )
        break
      case fileDropAreaStates.PROCESSING:
        dropAreaContent = (
          <DropAreaPending
            fileType={fileType}
            description={dropAreaPendingText}
          />
        )
        break
      case fileDropAreaStates.FAILED: {
        const uploadErrorString = Object.keys(uploadErrors)
          .map((key) => uploadErrors[key])
          .join(', ')
        dropAreaContent = (
          <DropAreaFailed
            disableRetry={error?.disableRetry}
            heading={error?.heading}
            message={error?.message || uploadErrorString}
            retryLabel={dropAreaErrorRetryLabel}
          />
        )
        break
      }
      case fileDropAreaStates.FINISHED:
        // In almost all cases this text will not be shown because upon finished serialized
        // HTML is shown for the block.
        dropAreaContent = (
          <Typography color="textSecondary">File is Uploaded :)</Typography>
        )
        break
      default:
        throw new Error(
          `File Drop Area uploading status of (${status}) does not exist`,
        )
    }

    return dropAreaContent
  }

  return (
    <section
      data-testid="file-drop-area"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...getRootProps()}
      className={classes.dropArea}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input {...getInputProps()} />
      {getDropAreaContent()}
    </section>
  )
}

FileDropArea.propTypes = {
  /**
   * Expected file types in file upload controls.
   * (https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#Unique_file_type_specifiers).
   * */
  acceptedFileTypes: PropTypes.string,
  blockId: PropTypes.string.isRequired,
  error: PropTypes.shape(blockContentErrorProps),
  /** The source url of the file available to download. */
  fileSourceUrl: PropTypes.string,
  /**
   * Type of file to be uploaded. (i.e. 'image', 'audio', 'video')
   * This is used in the text to describe specific file to upload.
   */
  fileType: PropTypes.string,
  /**
   * Indicates if the content is processing api-side and will display
   * a loading state until processed.
   * */
  isContentProcessing: PropTypes.bool,
  /**
   * Material UI icon to display in the file drop area.
   *
   * This is the functional component being passed,
   * not JSX-instantiated version. So, `muiIcon={CloudUploadIcon}`,
   * instead of `muiIcon={<CloudUploadIcon />}`.
   *
   * Find icons at: https://material-ui.com/components/material-icons/.
   * */
  muiIcon: PropTypes.elementType,
  /**
   * (Optional) Override the component's upload status manually.
   */
  uploadStatusOverride: PropTypes.string,
  ...dropAreaProps,
}

FileDropArea.defaultProps = {
  acceptedFileTypes: '',
  error: null,
  fileSourceUrl: '',
  fileType: '',
  isContentProcessing: false,
  muiIcon: undefined,
  uploadStatusOverride: '',
  ...dropAreaDefaultProps,
}
