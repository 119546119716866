/**
 * @module ConfirmDialog
 */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

/**
 * Confirmation dialog used to present confirm/deny choice to the user.
 *
 * @alias module:ConfirmDialog
 *
 * @param {object} props - The component props object.
 * @param {string} [props.confirmButtonLabel] - The label text for the Confirm button. Defaults to "Yes".
 * @param {string} props.content - The main text content of the dialog.
 * @param {string} [props.denyButtonLabel] - The label text for the Deny button. Defaults to "No".
 * @param {boolean} [props.open] - An optional open state. Defaults to false.
 * @param {Function} props.onClose - The close event handler, dispatched when the confirmation dialog is triggered to close.
 * @param {Function} props.onConfirm - The confirm button event handler, dispatched when the confirmation button is clicked.
 * @param {Function} props.onDeny - The deny button event handler, dispatched when the deny button is clicked.
 * @param {string} [props.title] - An optional title to use for the dialog.
 *
 * @returns {React.ReactElement} - The ConfirmDialog component.
 *
 * @example
 * <ConfirmDialog content="Are you sure you want to delete this section?" open={true} onClose={setDialogOpen(false)} onConfirm={() => { deleteBlock(blockId) }} onDeny={setDialogOpen(false)} />
 */
export function ConfirmDialog({
  confirmButtonLabel,
  content,
  denyButtonLabel,
  onClose,
  onConfirm,
  onDeny,
  open,
  title,
}) {
  return (
    <>
      <Dialog
        aria-describedby="confirm-dialog-content"
        aria-labelledby="confirm-dialog-title"
        onClose={onClose}
        open={open}
      >
        {title ? (
          <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
        ) : null}
        <DialogContent>
          <DialogContentText id="confirm-dialog-content">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDeny} color="primary">
            {denyButtonLabel}
          </Button>
          <Button onClick={onConfirm} color="primary">
            {confirmButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

ConfirmDialog.propTypes = {
  /** The label text for the Confirm button. Defaults to "Yes". */
  confirmButtonLabel: PropTypes.string,
  /** The main text content of the dialog. */
  content: PropTypes.string.isRequired,
  /** The label text for the Confirm button. Defaults to "No". */
  denyButtonLabel: PropTypes.string,
  /** The close event handler, dispatched when the confirmation dialog is triggered to close. */
  onClose: PropTypes.func.isRequired,
  /** The confirm button event handler, dispatched when the confirmation button is clicked. */
  onConfirm: PropTypes.func.isRequired,
  /** The deny button event handler, dispatched when the deny button is clicked. */
  onDeny: PropTypes.func.isRequired,
  /** An optional open state. Defaults to false. */
  open: PropTypes.bool,
  /** An optional title to use for the dialog. */
  title: PropTypes.string,
}

ConfirmDialog.defaultProps = {
  confirmButtonLabel: 'Yes',
  denyButtonLabel: 'No',
  open: false,
  title: null,
}
