/**
 * @module ImageBlock
 */
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { LinearProgress, Collapse, Box } from '@mui/material'
import { BaseBlock } from 'components/blocks/base-block'
import { FileDropArea, fileDropAreaStates } from 'components/blocks/drop-area'
import { useAsyncImage } from 'hooks'
import { serializeImage } from 'serializers'
import { createReactDangerousMarkup } from 'utils'
import {
  blockContentErrorProps,
  dropAreaDefaultProps,
  dropAreaProps,
} from 'constants/prop-types'

/**
 * @typedef ImageObject
 * @property {string} [alt] - An optional image alt tag string.
 * @property {string} display_url - The final image source url for display.
 * @property {string} [error] - An error message from the block to be displayed.
 * @property {string} file_id - The uploaded file's id.
 * @property {string} source_url - The original source file's url.
 */

/**
 * Image Block Component.
 *
 * @param {object} props - The component props object.
 * @param {string} props.blockId - The block's id.
 * @param {string} props.className - Optional additional className string.
 * @param {ImageObject} props.content - The image block content object.
 * @param {string|React.ReactElement} [props.dropAreaDownloadText] - The drop area download state descriptive text.
 * @param {string} [props.dropAreaDownloadButtonLabel] - The drop area download state button label.
 * @param {string|React.ReactElement} [props.dropAreaEmptyText] - The drop area empty state descriptive text.
 * @param {string} [props.dropAreaEmptyUploadButtonLabel] - The drop area empty state upload button label.
 * @param {string} [props.dropAreaActiveFileNotSupportedText] - The drop area active state file not supported text.
 * @param {string} [props.dropAreaActiveUploadText] - The drop area active state upload text.
 * @param {string} [props.dropAreaErrorRetryLabel] - The drop area error state retry label.
 * @param {string} [props.dropAreaUploadingText] - The drop area uploading state descriptive text.
 * @param {string} [props.dropAreaPendingText] - The drop area pending state descriptive text.
 * @param {object} props.dragHandleProps - Object provided by React Beautiful DND to create a compatible drag handle.
 * @param {boolean} props.isDragging - Provided by React Beautiful DND to indicate the block is being dragged.
 * @param {Function} props.onDelete - The delete handler.
 * @param {React.ReactElement} props.muiIcon - Material UI icon to display in the file drop area.
 *
 * @returns {React.ReactElement} - The image block component.
 *
 * @example <ImageBlock blockId={newId()} content={{ alt: "Bible Logo", display_url: "https://bible.com/logo.png" }} />
 */
export function ImageBlock({
  blockId,
  className,
  content,
  dropAreaDownloadText,
  dropAreaDownloadButtonLabel,
  dropAreaEmptyText,
  dropAreaEmptyUploadButtonLabel,
  dropAreaActiveFileNotSupportedText,
  dropAreaActiveUploadText,
  dropAreaErrorRetryLabel,
  dropAreaUploadingText,
  dropAreaPendingText,
  dragHandleProps,
  isDragging,
  muiIcon,
  onDelete,
}) {
  const { error: blockError, display_url, source_url } = content

  const {
    error: imagePreviewError,
    pending: isLoading,
    ready: isLoaded,
  } = useAsyncImage(display_url)
  let error
  if (blockError) {
    error = blockError
  } else if (imagePreviewError?.message) {
    error = { message: imagePreviewError?.message }
  }

  const isFilePreviewable = Boolean(!error && display_url)

  const ImageBlockPreview = (
    <>
      {isLoading ? (
        <Box mt={1}>
          <LinearProgress />
        </Box>
      ) : null}
      <Collapse in={isLoaded}>
        {isLoaded ? (
          <div
            dangerouslySetInnerHTML={createReactDangerousMarkup(
              serializeImage(content),
            )}
          />
        ) : null}
      </Collapse>
    </>
  )

  const FileUploader = (
    <FileDropArea
      acceptedFileTypes="image/png, image/jpg, image/jpeg"
      blockId={blockId}
      dropAreaDownloadText={dropAreaDownloadText}
      dropAreaDownloadButtonLabel={dropAreaDownloadButtonLabel}
      dropAreaEmptyText={dropAreaEmptyText}
      dropAreaEmptyUploadButtonLabel={dropAreaEmptyUploadButtonLabel}
      dropAreaErrorRetryLabel={dropAreaErrorRetryLabel}
      dropAreaPendingText={dropAreaPendingText}
      dropAreaUploadingText={dropAreaUploadingText}
      error={error}
      fileSourceUrl={source_url}
      fileType="image"
      muiIcon={muiIcon}
      uploadStatusOverride={
        source_url ? fileDropAreaStates.DOWNLOAD_FILE : null
      }
      dropAreaActiveFileNotSupportedText={dropAreaActiveFileNotSupportedText}
      dropAreaActiveUploadText={dropAreaActiveUploadText}
    />
  )

  return (
    <BaseBlock
      className={clsx('MUI-Block-Editor_block-image', className)}
      dragHandleProps={dragHandleProps}
      isDragging={isDragging}
      onDelete={onDelete}
    >
      {isFilePreviewable ? ImageBlockPreview : FileUploader}
    </BaseBlock>
  )
}

ImageBlock.propTypes = {
  blockId: PropTypes.string.isRequired,
  className: PropTypes.string,
  content: PropTypes.shape({
    alt: PropTypes.string,
    display_url: PropTypes.string.isRequired,
    error: PropTypes.shape(blockContentErrorProps),
    file_id: PropTypes.string.isRequired,
    source_url: PropTypes.string.isRequired,
  }),
  dragHandleProps: PropTypes.object.isRequired,
  isDragging: PropTypes.bool.isRequired,
  muiIcon: PropTypes.elementType,
  onDelete: PropTypes.func.isRequired,
  ...dropAreaProps,
}

ImageBlock.defaultProps = {
  className: null,
  ...dropAreaDefaultProps,
}
