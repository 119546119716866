/**
 * @module SerializeAudio
 */

/**
 * @typedef AudioObject
 * @property {string} [postprocessing_url] - An audio file url.
 */

/**
 * Serialize the provided audio block object into HTML.
 *
 * @alias module:serializeAudio
 *
 * @param {AudioObject} content - The content to serialize.
 * @param {object} [params] - Optional params object. // TODO - figure out what params does...
 *
 * @returns {(string|null)} The serialized audio content as HTML, or null.
 */
// eslint-disable-next-line no-empty-pattern
export function serializeAudio(content, {} = {}) {
  if (content.postprocessing_url === null) {
    return null
  }

  return `<audio
    style="width:100%" 
    controls="true"
    src="${content.postprocessing_url}"
    preload="metadata"
  >
    <p>There is an audio attachment for this devotional. You can <a href="${content.postprocessing_url}" target="_blank">download the audio</a> if you wish.</p>
  </audio>`
}
