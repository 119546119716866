/**
 * @module YVVideoBlock
 */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Container, Skeleton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { BaseBlock } from 'components/blocks/base-block'
import { FileDropArea, fileDropAreaStates } from 'components/blocks/drop-area'
import {
  blockContentErrorProps,
  dropAreaProps,
  dropAreaDefaultProps,
} from 'constants/prop-types'

const useStyles = makeStyles(() => ({
  hidden: {
    visibility: 'hidden',
  },
  videoContainer: {
    height: '0',
    paddingBottom: '56.25%',
    position: 'relative',
    width: '100%',
  },
  videoFrame: {
    height: '100%',
    left: '0',
    position: 'absolute',
    top: '0',
    width: '100%',
  },
}))

/**
 * @typedef VideoSource
 * @property {string} url - The video source url.
 * @property {string} html_mime_type - The video source type.
 * @property {string} [video_codec] - The video codecs.
 * @property {string} [audio_codec] - The audio codecs.
 */

/**
 * @typedef VideoObject
 * @property {string} [error] - An error message from the block to be displayed.
 * @property {string} file_id - The video file upload status.
 * @property {string} source_url - The original source file's url.
 * @property {string} poster_url - The Poster video url.
 * @property {VideoSource[]} sources - The Poster video url.
 */

/**
 * YV Video Block Component.
 *
 * @param {object} props - The component props object.
 * @param {string} props.blockId - The block's id.
 * @param {string} props.className - Optional additional className string.
 * @param {VideoObject} props.content - The video block content object.
 * @param {string|React.ReactElement} [props.dropAreaDownloadText] - The drop area download state descriptive text.
 * @param {string} [props.dropAreaDownloadButtonLabel] - The drop area download state button label.
 * @param {string|React.ReactElement} [props.dropAreaEmptyText] - The drop area empty state descriptive text.
 * @param {string} [props.dropAreaEmptyUploadButtonLabel] - The drop area empty state upload button label.
 * @param {string} [props.dropAreaActiveFileNotSupportedText] - The drop area active state file not supported text.
 * @param {string} [props.dropAreaActiveUploadText] - The drop area active state upload text.
 * @param {string} [props.dropAreaErrorRetryLabel] - The drop area error state retry label.
 * @param {string} [props.dropAreaUploadingText] - The drop area uploading state descriptive text.
 * @param {string} [props.dropAreaPendingText] - The drop area pending state descriptive text.
 * @param {object} props.dragHandleProps - Object provided by React Beautiful DND to create a compatible drag handle.
 * @param {boolean} props.isDragging - Provided by React Beautiful DND to indicate the block is being dragged.
 * @param {React.ReactElement} props.muiIcon - Material UI icon to display in the file drop area.
 * @param {Function} props.onDelete - The delete handler.
 *
 * @returns {React.ReactElement} - The yv video block component.
 *
 * @example <YVVideoBlock blockId={newId()} content={{ poster_url: "https://images.example.com/image.jpg", sources: [{url: "https://video.example.com/high_webm.webm", html_mime_type: "video/webm"}] }} />
 */
export function YVVideoBlock({
  blockId,
  className,
  content,
  dropAreaDownloadText,
  dropAreaDownloadButtonLabel,
  dropAreaEmptyText,
  dropAreaEmptyUploadButtonLabel,
  dropAreaActiveFileNotSupportedText,
  dropAreaActiveUploadText,
  dropAreaErrorRetryLabel,
  dropAreaPendingText,
  dropAreaUploadingText,
  dragHandleProps,
  isDragging,
  muiIcon,
  onDelete,
}) {
  const [isLoaded, setIsLoaded] = React.useState(false)
  const classes = useStyles()
  const {
    error,
    source_url,
    poster_url: propsPosterUrl,
    sources: propsSources,
  } = content

  const posterUrl = useMemo(() => propsPosterUrl || '', [propsPosterUrl])
  const sources = useMemo(() => propsSources || [], [propsSources])

  const isFilePreviewable = Boolean(!error && posterUrl && sources.length)

  // Normally we'd use the video serializer to get the HTML for the Video Block.
  // Because we need to know when the video is loading, I found it easier to just
  // use the JSX version of it.
  const VideoBlockPreview = (
    <Container className={classes.videoContainer}>
      {!isLoaded ? (
        <Skeleton className={classes.videoFrame} variant="rectangular" />
      ) : null}
      <video
        controls=""
        poster={posterUrl}
        className={clsx(classes.videoFrame, !isLoaded && classes.hidden)}
        onLoad={() => setIsLoaded(true)}
      >
        {sources.map((source) => {
          const codecs = []
          if (source.video_codec) codecs.push(source.video_codec)
          if (source.source_codec) codecs.push(source.source_codec)

          return (
            <source
              key={source.url}
              src={source.url}
              type={`${source.html_mime_type}${
                codecs.length ? `; codecs="${codecs.join(', ')}"` : ''
              }`}
            />
          )
        })}
      </video>
    </Container>
  )

  const FileUploader = (
    <FileDropArea
      acceptedFileTypes="video/*"
      blockId={blockId}
      dropAreaDownloadText={dropAreaDownloadText}
      dropAreaDownloadButtonLabel={dropAreaDownloadButtonLabel}
      dropAreaEmptyText={dropAreaEmptyText}
      dropAreaEmptyUploadButtonLabel={dropAreaEmptyUploadButtonLabel}
      dropAreaErrorRetryLabel={dropAreaErrorRetryLabel}
      dropAreaPendingText={dropAreaPendingText}
      dropAreaUploadingText={dropAreaUploadingText}
      error={error}
      fileSourceUrl={source_url}
      fileType="video"
      muiIcon={muiIcon}
      uploadStatusOverride={
        source_url ? fileDropAreaStates.DOWNLOAD_FILE : null
      }
      dropAreaActiveFileNotSupportedText={dropAreaActiveFileNotSupportedText}
      dropAreaActiveUploadText={dropAreaActiveUploadText}
    />
  )

  return (
    <BaseBlock
      className={clsx('MUI-Block-Editor_block-video', className)}
      dragHandleProps={dragHandleProps}
      isDragging={isDragging}
      onDelete={onDelete}
    >
      {isFilePreviewable ? VideoBlockPreview : FileUploader}
    </BaseBlock>
  )
}

YVVideoBlock.propTypes = {
  blockId: PropTypes.string.isRequired,
  className: PropTypes.string,
  content: PropTypes.shape({
    error: PropTypes.shape(blockContentErrorProps),
    file_id: PropTypes.string.isRequired,
    poster_url: PropTypes.string.isRequired,
    source_url: PropTypes.string.isRequired,
    sources: PropTypes.arrayOf(
      PropTypes.shape({
        audio_codec: PropTypes.string,
        html_mime_type: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        video_codec: PropTypes.string,
      }),
    ),
  }),
  dragHandleProps: PropTypes.object.isRequired,
  isDragging: PropTypes.bool.isRequired,
  muiIcon: PropTypes.elementType,
  onDelete: PropTypes.func.isRequired,
  ...dropAreaProps,
}

YVVideoBlock.defaultProps = {
  className: null,
  ...dropAreaDefaultProps,
}
