/**
 * @module useModel
 */

import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { makeStateModel } from '@youversion/api/core'

/**
 * List of valid async status types possible within useModel hook.
 */
export const statusTypes = makeStateModel([
  'idle',
  'error',
  'loading',
  'loadError',
  'loadSuccess',
  'updating',
  'updateSuccess',
  'deleting',
  'deleteSuccess',
])

/**
 * @typedef {object} UseModelState
 * @property {object} model
 * @property {Function} handleAttributeChange
 * @property {Function} handleSave
 * @property {Function} handleDelete
 * @property {Function} handleBlur
 * @property {Function} isTouched
 * @property {string} status
 * @property {Error} error
 */

/**
 * Hook to fetched, cache and update a model with react-query.
 *
 * @param {object} [config]
 * @param {string} [config.queryKey]
 * @param {Function} [config.queryFn]
 * @param {Function} [config.buildValidationError]
 * @param {Function} [config.createFn]
 * @param {Function} [config.updateFn]
 * @param {Function} [config.deleteFn]
 * @param {object} [config.queryConfig]
 * @returns {UseModelState}
 */
export function useModel({
  queryKey = null,
  queryFn = null,
  buildValidationError = null,
  createFn,
  updateFn,
  deleteFn,
  queryConfig = {},
}) {
  const [model, setModel] = React.useState()
  const [status, setStatus] = React.useState(statusTypes.loading)
  const [error, setError] = React.useState()
  const [touched, setTouched] = React.useState({})

  const { status: queryStatus, data, error: queryError } = useQuery(
    [queryKey],
    queryFn,
    queryConfig,
  )

  /**
   * Handle onChange events from input and update Model attributes with new values.
   *
   * @param {object} event
   */
  function handleAttributeChange(event) {
    const { value, name } = event.target
    if (model && model.$update) {
      setModel(model.$update(name, value))
    }
  }

  /**
   * Handle onBlur events from input and update Model. This is how the library
   * determines that a user has touched an input and is ready for the validation
   * logic to render validation errors if necessary.
   *
   * @param {object} event
   */
  function handleBlur(event) {
    const { name } = event.target
    setTouched({ ...touched, [name]: true })
  }

  /**
   * Determines if an item has been touched.
   *
   * @param {string} name
   * @returns {boolean}
   */
  function isTouched(name) {
    return Boolean(touched[name] && model && model[name].$touched)
  }

  /**
   * Handle Model delete operation.
   */
  async function handleDelete() {
    if (!(typeof deleteFn === 'function')) {
      const e = new Error(
        "You must pass 'deleteFn' to useModel options to support delete operations.",
      )
      setError(e)
      return
    }

    try {
      setStatus(statusTypes.deleting)
      await deleteFn(model)
      setStatus(statusTypes.deleteSuccess)
    } catch (deleteError) {
      setError(deleteError)
      setStatus(statusTypes.error)
    }
  }

  /**
   * Handle Model save operation.
   */
  async function handleSave() {
    if (!model) return

    if (!model.$valid) {
      if (typeof buildValidationError === 'function') {
        setError(buildValidationError(model))
      } else {
        setError(
          new Error(
            `Model Validation Error: ${model.$error}. Pass 'buildValidationError' function to useModel to customize this message.`,
          ),
        )
      }
      return
    }

    setStatus(statusTypes.updating)
    setError(null)
    try {
      const saveFn = model.$id ? updateFn : createFn
      await saveFn(model)
      // This functionality was removed, because it was trying to update an unmounted component
      // const result = await saveFn(model)
      // if (result) setModel(result)
      // setStatus(statusTypes.updateSuccess)
    } catch (saveError) {
      setError(saveError)
      setStatus(statusTypes.error)
    }
  }

  React.useEffect(() => {
    if (queryStatus === statusTypes.error) setStatus(statusTypes.loadError)
    else if (queryStatus === statusTypes.loading) setStatus(statusTypes.loading)
  }, [queryStatus])

  React.useEffect(() => {
    setError(queryError)
  }, [queryError])

  React.useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setModel(data)
      setStatus(statusTypes.loadSuccess)
    }
  }, [data])

  return {
    model,
    handleAttributeChange,
    handleBlur,
    handleSave,
    handleDelete,
    isTouched,
    status,
    error,
  }
}

useModel.statusTypes = statusTypes
