/** @module configProps */
import PropTypes from 'prop-types'
import { dropAreaProps } from 'constants/prop-types/drop-area-props'

export const configProps = PropTypes.shape({
  blockTypes: PropTypes.shape({
    audio: PropTypes.shape(dropAreaProps),
    image: PropTypes.shape(dropAreaProps),
    text: PropTypes.shape({}),
    video: PropTypes.shape(dropAreaProps),
    youtube_video: PropTypes.shape({}),
  }),
  strings: PropTypes.shape({
    addBlock: PropTypes.string,
    deleteBlockLabel: PropTypes.string,
    confirmDeleteBlockButtonLabel: PropTypes.string,
    confirmDeleteBlockContent: PropTypes.string,
    denyDeleteBlockButtonLabel: PropTypes.string,
    moveBlockLabel: PropTypes.string,
  }),
  handleFileUpload: PropTypes.func,
})
