/**
 * @module DropAreaFailed
 */

import React from 'react'
import { Typography, Box } from '@mui/material'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'
import { RoundedButton } from 'components/ui/buttons/rounded-button'
import {
  blockContentErrorProps,
  blockContentErrorDefaultProps,
} from 'constants/prop-types'

/**
 * Display this component while your block has encountered some kind of error.
 *
 * @alias module:DropAreaFailed
 *
 * @param {object} props - Component props object.
 * @param {boolean} [props.disableRetry] - Disable the retry functionality.
 * @param {string} [props.heading] - Custom error heading to display to end users.
 * @param {string} [props.message] - Custom error message to display to end users.
 * @param {string} [props.retryLabel] - Label for retrying upload.
 *
 * @returns {React.ReactElement} - The visual component for a Block's "error" state.
 */
export function DropAreaFailed({ disableRetry, heading, message, retryLabel }) {
  return (
    <React.Fragment>
      <SentimentDissatisfiedIcon fontSize="large" color="action" />
      <Box mt={1} />
      <Typography color="textSecondary" variant="h6" gutterBottom={true}>
        {heading}
      </Typography>
      <Typography color="textSecondary">{message}</Typography>
      <Box mt={2} />
      {!disableRetry ? (
        <RoundedButton color="primary" variant="outlined">
          {retryLabel ?? 'Try Again'}
        </RoundedButton>
      ) : null}
    </React.Fragment>
  )
}
DropAreaFailed.propTypes = blockContentErrorProps
DropAreaFailed.defaultProps = blockContentErrorDefaultProps
