/**
 * @module ReadOnlyHTMLBlock
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx' // comes as a dep in Material-UI
import LockIcon from '@mui/icons-material/Lock'
import { BaseBlock } from 'components/blocks/base-block'
import { createReactDangerousMarkup } from 'utils'

const useStyles = makeStyles((theme) => ({
  iconLock: {
    height: '100%',
    marginRight: theme.spacing(0.5),
  },
  readOnlyHTML: {
    border: `2px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.spacing(1),
    fontFamily: theme.typography.fontFamily,
    // eslint-disable-next-line sort-keys
    '& iframe, & img': { width: '100%', maxWidth: '100%' },
    '& table': { maxWidth: '100%' },
  },
  readOnlyText: {
    display: 'flex',
    textTransform: 'uppercase',
  },
}))

/**
 * @typedef ReadOnlyHTML
 * @property {string} html - The html string.
 */
/**
 * Read-Only HTML Block Component.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.className] - CSS Class names added to the rendered read-only HTML of this component.
 * @param {ReadOnlyHTML} props.content - HTML markup.
 * @param {boolean} props.disableDelete - The option to disable delete.
 * @param {Function} props.onDelete - The delete handler.
 *
 * @returns {React.ReactElement} - The image block component.
 *
 * @example <ReadOnlyHTMLBlock content={{html: "<a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ' target='_blank'>Never Gonna Give You Up</a>"}} />
 */
export function ReadOnlyHTMLBlock({
  className,
  content,
  disableDelete,
  onDelete,
}) {
  const classes = useStyles()
  return (
    <BaseBlock
      dragHandleProps={{}}
      isDragging={false}
      onDelete={onDelete}
      disableDrag={true}
      disableDelete={disableDelete}
    >
      <Box display="flex" alignContent="center">
        <Typography
          className={classes.readOnlyText}
          color="textSecondary"
          variant="caption"
        >
          <LockIcon className={classes.iconLock} fontSize="inherit" />
          Read-Only
        </Typography>
      </Box>
      <Box
        className={clsx(classes.readOnlyHTML, className)}
        dangerouslySetInnerHTML={createReactDangerousMarkup(content.html)}
        p={2}
      />
    </BaseBlock>
  )
}

ReadOnlyHTMLBlock.propTypes = {
  className: PropTypes.string,
  content: PropTypes.shape({
    html: PropTypes.string.isRequired,
  }).isRequired,
  disableDelete: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
}

ReadOnlyHTMLBlock.defaultProps = {
  className: '',
}
