/**
 * @module DropAreaPending
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Box, LinearProgress } from '@mui/material'

/**
 * Display this component when the file is still processing api-side.
 *
 * @alias module:DropAreaPending
 *
 * @param {object} [props] - The component props.
 * @param {string|React.ReactElement} [props.description] - The drop area pending state's descriptive text.
 *
 * @returns {React.ReactElement} - The visual component for the drop area's "processing" state. (E.g., what gets displayed when the file is being processed api-side).
 */
export function DropAreaPending({ description }) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width="100%"
    >
      <Box width="50%" mb={2}>
        <LinearProgress />
      </Box>
      {typeof description === 'string' ? (
        <Typography color="textSecondary">
          {description ||
            'Content is processing and will be ready momentarily…'}
        </Typography>
      ) : (
        description || 'Content is processing and will be ready momentarily…'
      )}
    </Box>
  )
}

DropAreaPending.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

DropAreaPending.defaultProps = {
  description: '',
}
