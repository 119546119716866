/**
 * @module RoundedButton
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  roundedButton: {
    borderRadius: 50,
  },
})
/**
 * A rounded variant of the Material-UI Button component. Works exactly like Button, just with a rounded style.
 *
 * @param {object} props - The component props object.
 * @param {React.ReactChildren} [props.children] - Optional React children.
 * @param {string} [props.className] - Optional CSS classes for button.
 *
 * @returns {React.ReactElement} - The RoundedButton component.
 *
 * @see {@link https://material-ui.com/components/buttons/#button}.
 *
 * @example
 * import { RoundedButton } from '@youversion/react'
 *
 * <RoundedButton variant="filled" onClick={() => {}}>Click Me</RoundedButton>
 *
 */
export function RoundedButton({ children, className, ...props }) {
  const classes = useStyles()
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button className={`${classes.roundedButton} ${className}`} {...props}>
      {children}
    </Button>
  )
}

RoundedButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
RoundedButton.defaultProps = {
  children: null,
  className: '',
}
