/**
 * @module TextBlock
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Card } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { ContentState, EditorState, Modifier, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import htmlToDraft from 'html-to-draftjs'
import { BaseBlock } from 'components/blocks/base-block'
import { serializeText } from 'serializers'

// TODO: PP-397 - Investigate WYSIWYG Alternatives
const toolbarPositions = { bottom: 'bottom', top: 'top' }

const toolbarModalEventIds = ['linkTarget', 'linkTitle', 'openLinkInNewWindow']

const useStyles = makeStyles((theme) => ({
  activeCard: {
    // The hard-coded height is the size of the toolbar at different breakpoints.
    '& .RichEditor-toolbar-active': {
      height: 46,
      opacity: 1,
      [theme.breakpoints.down('sm')]: {
        height: 82,
      },
      [theme.breakpoints.down('xs')]: {
        height: 118,
      },
    },
    overflow: 'visible',
  },
  editorContainer: {
    '& .rdw-editor-toolbar': {
      boxSizing: 'border-box',
      transition: `height 0.25s ${theme.transitions.easing.easeInOut},
        border 0.25s ${theme.transitions.easing.easeInOut},
        opacity 0.15s ${theme.transitions.easing.easeInOut}`,
    },
    // This spacing helps align the placeholder text with the left block drag handle.
    '& .RichEditor-content-block': {
      marginBlockEnd: theme.spacing(1),
      marginBlockStart: theme.spacing(1),
    },
  },
  inactiveCard: {
    '& .RichEditor-toolbar-inactive': {
      display: 'flex',
      height: '0px',
      opacity: [0, '!important'],
      visibility: 'visible',
    },
    background: 'none',
    minHeight: '100%',
  },
}))

/**
 * @typedef HTMLBlock
 * @property {string} html - The html string.
 */
/**
 * Text Block Component.
 *
 * @alias module:TextBlock
 *
 * @param {object} props - The component props object.
 * @param {string} props.blockId - The block's id.
 * @param {HTMLBlock} props.content - The block content.
 * @param {string[]} props.customPlaceholderStrings - Optional array of placeholder strings, one of which is chosen at random for the text block's placeholder text.
 * @param {string} [props.defaultPlaceholder] - Default placeholder string for the text block.
 * @param {object} props.dragHandleProps - Object provided by React Beautiful DND to create a compatible drag handle.
 * @param {boolean} props.isDragging - Provided by React Beautiful DND to indicate the block is being dragged.
 * @param {Function} props.onChange - The change handler.
 * @param {Function} props.onDelete - The delete handler.
 * @param {object} props.toolbarOptions - Toolbar options for the `react-draft-wysiwyg` text editor. Visit [the docs]{@link https://jpuri.github.io/react-draft-wysiwyg/#/docs} for more info.
 * @param {string} props.toolbarPosition - Toolbar position. One of 'top' or 'bottom'.
 *
 * @returns {React.ReactElement} - The text block component.
 *
 * @example
 * return (
 * <TextBlock blockId={newId()} content={{html: "Here is some content"}} onDelete={onDelete} />
 * )
 */
export function TextBlock({
  blockId,
  content,
  customPlaceholderStrings,
  dragHandleProps,
  isDragging,
  onChange,
  onDelete,
  toolbarOptions,
  toolbarPosition,
  defaultPlaceholder,
}) {
  const classes = useStyles()
  const blocksFromHtml = htmlToDraft(content.html)
  const [isActive, setIsActive] = React.useState(false)
  const [editorRef, setEditorRef] = React.useState(null)
  const { contentBlocks, entityMap } = blocksFromHtml
  const [contentState] = React.useState(() =>
    ContentState.createFromBlockArray(contentBlocks, entityMap),
  )
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createWithContent(contentState),
  )
  const [placeholderMessage] = React.useState(() => {
    if (customPlaceholderStrings && customPlaceholderStrings?.length > 0) {
      return customPlaceholderStrings[
        Math.floor(Math.random() * customPlaceholderStrings.length)
      ]
    }

    // Default placeholder string approved by Brannon G. from the content team.
    return defaultPlaceholder ?? 'Click here to enter text'
  })

  function handleFocusOnCreation(ref) {
    setEditorRef(ref)
  }

  function handleBlur(event) {
    // This is a workaround for a bug in react-draft-wysiwyg. The editor will lose focus if a
    // toolbar modal is interacted with, firing `onBlur` prematurely. If the event gets fired
    // on one of the link modal fields, we won't do anything.
    if (!toolbarModalEventIds.includes(event.target.id)) {
      setIsActive(false)
    }
  }

  // Once we get the ref for the input, give it focus to
  // make it easy for the admin to start typing away!
  React.useEffect(() => {
    if (editorRef && typeof editorRef.focus === 'function') {
      editorRef.focus()
    }
  }, [editorRef])

  return (
    <BaseBlock
      dragHandleProps={dragHandleProps}
      isDragging={isDragging}
      onDelete={onDelete}
    >
      <Card
        className={clsx([
          classes.editorContainer,
          !isActive ? classes.inactiveCard : classes.activeCard,
        ])}
        elevation={0}
        variant={isActive ? 'outlined' : null}
      >
        <div
          className={clsx(
            'RichEditor-root',
            isActive ? 'RichEditor-active' : '',
            isDragging ? 'RichEditor-is-dragging' : '',
            `RichEditor-toolbar-${
              toolbarPositions[toolbarPosition] ? toolbarPosition : 'top'
            }`,
          )}
        >
          <Editor
            blockStyleFn={() => 'RichEditor-content-block'}
            editorRef={handleFocusOnCreation}
            editorState={editorState}
            handlePastedText={(text, html, prevEditorState) => {
              // If html content exists, generate state from the it and set the
              // editorState with the updated EditorState. Note that there is no
              // need to call `onChange` here, as the solution on which this is
              // derived does, since the `editorState` is set explicitly and
              // will be caught and handled in the `onEditorStateChange` prop.
              // This solution based on link below, updated to suit our needs.
              // Source: https://github.com/jpuri/react-draft-wysiwyg/issues/498#issuecomment-461793404
              if (html) {
                // Get the blockMap from our filtered HTML, so that we can insert it in correctly.
                const serializedHtml = serializeText({ html })

                // Sample from https://draftjs.org/docs/api-reference-data-conversion/#convertfromhtml
                // This allows me to convert the serialized/sanitized HTML into blocks fit for draft-js.
                const blocksFromHTML = convertFromHTML(serializedHtml)
                const { blockMap } = ContentState.createFromBlockArray(
                  blocksFromHTML.contentBlocks,
                  blocksFromHTML.entityMap,
                )

                // This inserts the filtered pasted code into the text at the correct place.
                const newState = Modifier.replaceWithFragment(
                  prevEditorState.getCurrentContent(),
                  prevEditorState.getSelection(),
                  blockMap,
                )
                setEditorState(
                  EditorState.push(
                    prevEditorState,
                    newState,
                    'insert-fragment',
                  ),
                )

                // This tells the editor not to follow through with the default paste behavior.
                return true
              }

              return false
            }}
            onEditorStateChange={(state) => {
              setEditorState(state)
              onChange(blockId, {
                content: { html: serializeText(state.getCurrentContent()) },
              })
            }}
            placeholder={placeholderMessage}
            stripPastedStyles={false}
            spellCheck={true}
            toolbar={toolbarOptions}
            toolbarClassName={
              isActive
                ? 'RichEditor-toolbar-active'
                : 'RichEditor-toolbar-inactive'
            }
            onFocus={() => setIsActive(true)}
            onBlur={handleBlur}
          />
        </div>
      </Card>
    </BaseBlock>
  )
}

TextBlock.propTypes = {
  blockId: PropTypes.string.isRequired,
  content: PropTypes.shape({
    html: PropTypes.string.isRequired,
  }).isRequired,
  customPlaceholderStrings: PropTypes.arrayOf(PropTypes.string),
  dragHandleProps: PropTypes.object.isRequired,
  isDragging: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  toolbarOptions: PropTypes.object,
  toolbarPosition: PropTypes.string,
  defaultPlaceholder: PropTypes.string,
}

TextBlock.defaultProps = {
  customPlaceholderStrings: [],
  toolbarOptions: {},
  toolbarPosition: toolbarPositions.top,
}
