/**
 * @module DropAreaDownloadFile
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button as MuiButton, Typography } from '@mui/material'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'

/**
 * Display this component when the file is uploaded to S3, or another bucket service, but
 * before it's uploaded to its destination service.
 *
 * @alias module:DropAreaDownloadFile
 *
 * @param {object} props - Component props object.
 * @param {React.ReactElement} [props.button] - An optional button component.
 * @param {string|React.ReactElement} [props.description] - Custom text string or React element to let the users know what to do.
 * @param {string} [props.downloadButtonText] - Custom text for the download button.
 * @param {React.ReactElement} [props.icon] - Material UI icon to display in the file drop area.
 * @param {string} props.sourceUrl - The source url of the file available to download.
 *
 * @returns {React.ReactElement} - The visual component for the drop area's "processing" state. (E.g., what gets displayed when the file is being processed api-side).
 */
export function DropAreaDownloadFile({
  button: Button,
  description,
  downloadButtonText,
  icon: Icon,
  sourceUrl,
}) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width="100%"
    >
      <Icon fontSize="large" color="action" />
      <Box my={2}>
        {typeof description === 'string' ? (
          <Typography color="textSecondary">
            {description || 'Your file will be processed later.'}
          </Typography>
        ) : (
          description || 'Your file will be processed later.'
        )}
      </Box>
      <Button
        color="primary"
        variant="outlined"
        href={sourceUrl}
        target="_blank"
        download={true}
      >
        {downloadButtonText || 'Download this file'}
      </Button>
    </Box>
  )
}

DropAreaDownloadFile.propTypes = {
  button: PropTypes.elementType,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  downloadButtonText: PropTypes.string,
  icon: PropTypes.elementType,
  sourceUrl: PropTypes.string.isRequired,
}

DropAreaDownloadFile.defaultProps = {
  button: MuiButton,
  description: '',
  downloadButtonText: '',
  icon: CloudDownloadIcon,
}
