/**
 * @module DropAreaDownloadFile
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Box } from '@mui/material'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { RoundedButton } from 'components/ui/buttons/rounded-button'

/**
 * Display this component when the block file is uploaded to S3, or another bucket service, but
 * before it's uploaded to its destination service.
 *
 * @alias module:DropAreaDownloadFile
 *
 * @param {object} props - Component props object.
 * @param {string|React.ReactElement} [props.description] - Custom text string or React element to let the users know what to do.
 * @param {string} [props.fileType] - Type of file to be uploaded. (i.e. 'image', 'audio', 'video').
 * @param {string} [props.downloadButtonLabel] - Download button label.
 * @param {React.ReactElement} [props.muiIcon] - Material UI icon to display in the file drop area.
 * @param {string} props.sourceUrl - The source url of the file available to download.
 *
 * @returns {React.ReactElement} - The visual component for a Block's "processing" state. (E.g., what gets displayed when the file is being processed api-side).
 */
export function DropAreaDownloadFile({
  description,
  fileType,
  muiIcon: DropAreaIcon,
  sourceUrl,
  downloadButtonLabel,
}) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width="100%"
    >
      <DropAreaIcon fontSize="large" color="action" />
      <Box mt={2} />
      {typeof description === 'string' ? (
        <Typography color="textSecondary">
          {description ??
            `Your ${fileType} file will be processed when the plan is approved.`}
        </Typography>
      ) : (
        description
      )}
      <Box mt={2}>
        {/* https://stackoverflow.com/q/11620698/6598861 */}
        {/* For the life of me I could not get the button to simple download without having to go to a new tab to display that file. */}
        <RoundedButton
          color="primary"
          variant="outlined"
          href={sourceUrl}
          target="_blank"
          download={true}
        >
          {downloadButtonLabel ?? `Download ${fileType}`}
        </RoundedButton>
      </Box>
    </Box>
  )
}

DropAreaDownloadFile.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Type of file to be uploaded. (i.e. 'image', 'audio', 'video')
   * This is used in the text to describe specific file to upload.
   */
  fileType: PropTypes.string,
  /** Material UI icon to display in the file drop area. */
  muiIcon: PropTypes.elementType,
  /** The source url of the file available to download. */
  sourceUrl: PropTypes.string.isRequired,
  downloadButtonLabel: PropTypes.string,
}

DropAreaDownloadFile.defaultProps = {
  description: '',
  fileType: '',
  muiIcon: CloudDownloadIcon,
}
