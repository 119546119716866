/**
 * @module AudioBlock
 */
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { BaseBlock } from 'components/blocks/base-block'
import { FileDropArea, fileDropAreaStates } from 'components/blocks/drop-area'
import { serializeAudio } from 'serializers'
import { createReactDangerousMarkup } from 'utils'
import {
  blockContentErrorProps,
  dropAreaProps,
  dropAreaDefaultProps,
} from 'constants/prop-types'

/**
 * @typedef AudioObject
 * @property {string} [error] - An error message from the block to be displayed.
 * @property {string} file_id - The uploaded file's id.
 * @property {string} postprocessing_url - The audio file url that has been processed server-side and is not the raw audio file.
 * @property {string} source_url - The original source file's url.
 */

/**
 * Audio Block Component.
 *
 * @param {object} props - The component props object.
 * @param {string} props.blockId - The block's id.
 * @param {string} props.className - Optional additional className string.
 * @param {AudioObject} props.content - The image block content object.
 * @param {string|React.ReactElement} [props.dropAreaDownloadText] - The drop area download state descriptive text.
 * @param {string} [props.dropAreaDownloadButtonLabel] - The drop area download state button label.
 * @param {string|React.ReactElement} [props.dropAreaEmptyText] - The drop area empty state descriptive text.
 * @param {string} [props.dropAreaEmptyUploadButtonLabel] - The drop area empty state upload button label.
 * @param {string} [props.dropAreaActiveFileNotSupportedText] - The drop area active state file not supported text.
 * @param {string} [props.dropAreaActiveUploadText] - The drop area active state upload text.
 * @param {string} [props.dropAreaErrorRetryLabel] - The drop area error state retry label.
 * @param {string} [props.dropAreaUploadingText] - The drop area uploading state descriptive text.
 * @param {string} [props.dropAreaPendingText] - The drop area pending state descriptive text.
 * @param {object} props.dragHandleProps - Object provided by React Beautiful DND to create a compatible drag handle.
 * @param {boolean} props.isDragging - Provided by React Beautiful DND to indicate the block is being dragged.
 * @param {Function} props.onDelete - The delete handler.
 * @param {React.ReactElement} props.muiIcon - Material UI icon to display in the file drop area.
 *
 * @returns {React.ReactElement} - The image block component.
 *
 * @example <AudioBlock blockId={newId()} content={{ html: "<p>SoundCloud Embed</p>" }} />
 */
export function AudioBlock({
  blockId,
  className,
  content,
  dropAreaDownloadText,
  dropAreaDownloadButtonLabel,
  dropAreaEmptyText,
  dropAreaEmptyUploadButtonLabel,
  dropAreaActiveFileNotSupportedText,
  dropAreaActiveUploadText,
  dropAreaErrorRetryLabel,
  dropAreaPendingText,
  dropAreaUploadingText,
  dragHandleProps,
  isDragging,
  muiIcon,
  onDelete,
}) {
  const { error, postprocessing_url, source_url } = content
  const isFilePreviewable = Boolean(!error && postprocessing_url)

  const AudioBlockPreview = (
    <div
      dangerouslySetInnerHTML={createReactDangerousMarkup(
        serializeAudio(content),
      )}
    />
  )

  const FileUploader = (
    <FileDropArea
      acceptedFileTypes="audio/*"
      blockId={blockId}
      dropAreaDownloadText={dropAreaDownloadText}
      dropAreaDownloadButtonLabel={dropAreaDownloadButtonLabel}
      dropAreaEmptyText={dropAreaEmptyText}
      dropAreaEmptyUploadButtonLabel={dropAreaEmptyUploadButtonLabel}
      dropAreaErrorRetryLabel={dropAreaErrorRetryLabel}
      dropAreaPendingText={dropAreaPendingText}
      dropAreaUploadingText={dropAreaUploadingText}
      error={error}
      fileSourceUrl={source_url}
      fileType="audio"
      muiIcon={muiIcon}
      uploadStatusOverride={
        source_url ? fileDropAreaStates.DOWNLOAD_FILE : null
      }
      dropAreaActiveFileNotSupportedText={dropAreaActiveFileNotSupportedText}
      dropAreaActiveUploadText={dropAreaActiveUploadText}
    />
  )

  return (
    <BaseBlock
      onDelete={onDelete}
      className={clsx('MUI-Block-Editor_block-audio', className)}
      dragHandleProps={dragHandleProps}
      isDragging={isDragging}
    >
      {isFilePreviewable ? AudioBlockPreview : FileUploader}
    </BaseBlock>
  )
}

AudioBlock.propTypes = {
  blockId: PropTypes.string.isRequired,
  className: PropTypes.string,
  content: PropTypes.shape({
    error: PropTypes.shape(blockContentErrorProps),
    file_id: PropTypes.string.isRequired,
    postprocessing_url: PropTypes.string.isRequired,
    source_url: PropTypes.string.isRequired,
  }),
  dragHandleProps: PropTypes.object.isRequired,
  isDragging: PropTypes.bool.isRequired,
  muiIcon: PropTypes.elementType,
  onDelete: PropTypes.func.isRequired,
  ...dropAreaProps,
}

AudioBlock.defaultProps = {
  className: null,
  ...dropAreaDefaultProps,
}
