/**
 * @module SerializeVideo
 */

/**
 * @typedef VideoObject
 * @property {string} [youtube_video_id] - The YouTube video id.
 */

/**
 * Serialize the provided video content.
 *
 * @alias module:serializeVideo
 *
 * @param {VideoObject} content - The content to serialize.
 *
 * @returns {string} The serialized video content.
 */
// eslint-disable-next-line no-empty-pattern
export function serializeVideo(content) {
  const { youtube_video_id } = content

  if (!youtube_video_id) {
    return null
  }

  return `<iframe 
    src='https://www.youtube.com/embed/${youtube_video_id}?rel=0' 
    width='100%'
    height='250'
    frameborder='0'
    allow='autoplay; encrypted-media'
    allowfullscreen>
  </iframe>`
}
