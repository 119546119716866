/**
 * @module blockContentErrorProps
 */
import PropTypes from 'prop-types'

export const blockContentErrorProps = {
  disableRetry: PropTypes.bool,
  heading: PropTypes.node,
  message: PropTypes.node,
  retryLabel: PropTypes.string,
}

export const blockContentErrorDefaultProps = {
  disableRetry: false,
  heading: 'Failed to Upload',
  message: 'An unexpected error occurred.',
}
