/**
 * @module SerializeVideo
 */

/**
 * @typedef VideoSource
 * @property {string} url - The video source url.
 * @property {string} html_mime_type - The video source type.
 * @property {string} [video_codec] - The video codecs.
 * @property {string} [audio_codec] - The audio codecs.
 */

/**
 * @typedef VideoObject
 * @property {string} poster_url - The Poster video url.
 * @property {VideoSource[]} sources - The Poster video url.
 */

/**
 * Serialize the provided yv video content.
 *
 * @alias module:serializeYVVideo
 *
 * @param {VideoObject} content - The content to serialize.
 *
 * @returns {string} The serialized video content.
 */
// eslint-disable-next-line no-empty-pattern
export function serializeYVVideo(content) {
  const { poster_url, sources } = content

  if (!poster_url) {
    return null
  }

  return `<video controls="" 
  poster="${poster_url}" 
  style="max-height: 540px; max-width: 960px;" 
  width="100%"
  >
    ${sources
      .map((source) => {
        const codecs = []
        if (source.video_codec) codecs.push(source.video_codec)
        if (source.source_codec) codecs.push(source.source_codec)

        return `
          <source 
            src="${source.url}" 
            type='${source.html_mime_type}${
          codecs.length ? `; codecs="${codecs.join(', ')}"` : ''
        }'
          >
        `
      })
      .join('\n')}
</video>`.replace(/\n/g, '')
}
