/** @module dropAreaProps */
import PropTypes from 'prop-types'

export const dropAreaText = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.element,
])

export const dropAreaProps = {
  dropAreaDownloadText: dropAreaText,
  dropAreaDownloadButtonLabel: PropTypes.string,
  dropAreaEmptyText: dropAreaText,
  dropAreaEmptyUploadButtonLabel: PropTypes.string,
  dropAreaActiveFileNotSupportedText: PropTypes.string,
  dropAreaActiveUploadText: PropTypes.string,
  dropAreaErrorRetryLabel: PropTypes.string,
  dropAreaUploadingText: PropTypes.string,
  dropAreaPendingText: PropTypes.string,
}

export const dropAreaDefaultProps = {
  dropAreaDownloadText: '',
  dropAreaEmptyText: '',
}
