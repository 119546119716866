/**
 * @module DropAreaDragActive
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Fade } from '@mui/material'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'

/**
 * Display this component while your Block is in the "drag active" state (a
 * state provided by the Drop Area hook).
 *
 * @alias module:DropAreaDragActive
 *
 * @param {object} props - Component props object.
 * @param {string} [props.fileNotSupportedCustomText] - Custom text for file not supported.
 * @param {string} [props.dropFileToUploadCustomText] - Custom text for drop file to upload.
 * @param {boolean} [props.isDragReject] - Indicates if the file being dragged over is invalid.
 * @param {string} [props.fileType] - Type of file to be uploaded. (i.e. 'image', 'audio', 'video').
 *
 * @returns {React.ReactElement} The visual component for a Block's "drag active" state.
 */
export function DropAreaDragActive({
  isDragReject,
  fileType = '',
  dropFileToUploadCustomText,
  fileNotSupportedCustomText,
}) {
  return (
    <Fade in={true}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        {isDragReject ? (
          <SentimentDissatisfiedIcon fontSize="large" color="action" />
        ) : (
          <InsertEmoticonIcon fontSize="large" color="action" />
        )}
        <Box mt={2} />
        <Typography color="textSecondary">
          {isDragReject
            ? fileNotSupportedCustomText ?? 'That file is not supported'
            : dropFileToUploadCustomText ??
              `Drop the ${fileType} file here to upload`}
        </Typography>
      </Box>
    </Fade>
  )
}

DropAreaDragActive.propTypes = {
  /** Type of file to be uploaded. (i.e. 'image', 'audio', 'video'). */
  fileType: PropTypes.string,
  /** Indicates if the file being dragged over is invalid. */
  isDragReject: PropTypes.bool.isRequired,
  fileNotSupportedCustomText: PropTypes.string,
  dropFileToUploadCustomText: PropTypes.string,
}

DropAreaDragActive.defaultProps = {
  // This is mainly for UX purposes, to inform the person that
  // we are uploading an 'image' file or an 'audio' file, etc.
  fileType: '',
}
