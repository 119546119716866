/**
 * @module useBlocks
 */
import React from 'react'
import arrayMove from 'array-move'
import { v4 as newId } from 'uuid'
import { actionTypes } from 'constants/action-types'
import { blockEditorContextProps } from 'context/block-editor-context-props'

// Validation handling for the content objects. We are validating the object is truthy to our
// standards: has one or more properties.
function validateContent({ content, contentParams }) {
  function isValidObject(obj) {
    if (!obj || typeof obj !== 'object' || Array.isArray(content)) {
      return false
    }

    return Object.keys(obj).length > 0
  }

  // Make sure we have only one valid object of content or contentParams.
  // Otherwise, throw a helpful error.
  if (isValidObject(content) === isValidObject(contentParams)) {
    throw new Error(
      'Expected either a non-empty content object or a non-empty contentParams object.',
    )
  }
}

/**
 * @typedef BlockContent
 * @property {string} [alt] - An image alt string.
 * @property {string} [display_url] - An image display URL string for the img `src` attribute.
 * @property {string} [file_id] - The file's id.
 * @property {string} [html] - An html string.
 * @property {string} [source_url] - A raw image URL string.
 * @property {string} [youtube_video_id] - The video's YouTube id.
 */

/**
 * The block reducer function. State must be an array.
 *
 * @param {Array} prevState - The previous state array.
 * @param {object} action - The action object.
 * @param {string} [action.actionType] - Optional action type. Valid action types are enumerated in the `actionTypes` object.
 * @param {string} action.blockId - The block id.
 * @param {BlockContent} [action.content] - Optional overall replacement of the block.content object.
 * @param {BlockContent} [action.contentParams] - Optional params to override on the existing block.content object.
 * @param {Function} [action.callback] - An optional callback to perform.
 * @param {object} action.supportedBlocks - Supported blocks object, typically `config.blockTypes`.
 * @throws {Error} - Throws an error if the block type is not supported.
 * @returns {any} - The reducer action.
 */
export function blockReducer(
  prevState,
  { actionType, blockId, callback, content, contentParams, supportedBlocks },
) {
  const index = prevState.findIndex((item) => item.block_id === blockId)
  if (callback && typeof callback === 'function') {
    return callback(prevState)
  }

  // We need to ensure we have an index for block reference moving forward in
  // our logic, otherwise we'll get null/undefined reference errors.
  if (index < 0) {
    return prevState
  }

  if (
    actionType &&
    !Object.keys(actionTypes).find((key) => actionTypes[key] === actionType)
  ) {
    throw new Error(`Unsupported action type ${actionType}`)
  }

  function defaultAction() {
    validateContent({ content, contentParams })
    const updatedState = [...prevState]
    let newContent = prevState[index].content
    if (contentParams) {
      newContent = {
        ...prevState[index].content,
        ...contentParams,
      }
    } else if (content) {
      newContent = content
    }
    updatedState[index].content = newContent
    return updatedState
  }

  if (!supportedBlocks) {
    throw new Error('supportedBlocks object is required.')
  }

  if (
    !Object.keys(supportedBlocks)
      .map((blockKey) => {
        return supportedBlocks[blockKey].name
      })
      .includes(prevState[index].type)
  ) {
    throw new Error(
      `Unsupported block type ${prevState[index].type} on block ${blockId}.`,
    )
  }

  return defaultAction()
}

/**
 * Block types that are enabled in Block Editor. Only these items will be used.
 *
 * @typedef {object} BlockEditorConfigBlockTypes
 * @property {React.ReactComponent} Component - The block type's component.
 * @property {boolean} [disableCreate] - Disable the ability to create this block type.
 * @property {string} [displayName] - The block type's display name. Defaults to the block.name property.
 * @property {React.ReactComponent} Icon - The block type's icon.
 * @property {object} [initialContentState] - The block.content property's initial state. Defaults to an empty object.
 * @property {string} name - The block type's name. Must be camelCase or snake_case. Must match the block type's key.
 * @property {Function} serialize - The function to serialize the block into html.
 */

/**
 * Strings used in block editor.
 *
 * @typedef {object} BlockEditorConfigStrings
 * @property {string} [addBlock] - Add block label.
 * @property {string} [deleteBlockLabel] - Delete block aria label.
 * @property {string} [confirmDeleteBlockButtonLabel] - Confirm Delete block button label.
 * @property {string} [confirmDeleteBlockContent] - Confirm Delete block content.
 * @property {string} [denyDeleteBlockButtonLabel] - Deny Delete block content.
 * @property {string} [moveBlockLabel] - Move a block.
 */

/**
 * Configuration options for the block editor.
 *
 * @typedef {object} BlockEditorConfig
 * @property {object<BlockEditorConfigBlockTypes>} blockTypes - Block types that are enabled in block editor.
 * @property {object<BlockEditorConfigStrings>} strings - Strings for global block editor texts.
 * @property {Function} handleFileUpload - The function to handle uploading files to a storage service.
 */

/**
 * @typedef {object} BlockEditorContext
 * @property {Array} blocks - The blocks state array.
 * @property {Function} blockReducer - The state reducer function.
 * @property {BlockEditorConfig} [config] - Custom configuration options.
 * @property {Function} deleteBlock - The method to delete a block.
 * @property {Function} insertNewBlock - The method to add a new block.
 * @property {Function} moveBlock - The method to rearrange block order.
 * @property {Function} updateBlock - Function to update a single block.
 * @property {Function} updateBlocks - Function to update all of the blocks.
 * @property {Function} getNewBlockByType - Gets new block object by its type.
 */
const BlockEditorContext = React.createContext({
  blockReducer: null,
  blocks: null,
  config: null,
  deleteBlock: null,
  getNewBlockByType: null,
  insertNewBlock: null,
  moveBlock: null,
  strings: null,
  updateBlock: null,
  updateBlocks: null,
})

/**
 * React Hook for accessing the block editor context.
 *
 * @throws {Error} - Throws an error if the hook is used outside of a context provider.
 *
 * @returns {BlockEditorContext} - The block editor context.
 */
export function useBlocks() {
  const context = React.useContext(BlockEditorContext)
  if (context === undefined) {
    throw new Error(
      `useBlocks must be used within a BlockEditorContextProvider`,
    )
  }
  return context
}

/**
 * Context to scope, use, and manage state within the block editor.
 *
 * @param {object} props - The context props object.
 * @param {Array} props.blocks - The array of blocks.
 * @param {BlockEditorConfig} [props.config] - Optional configuration object.
 * @param {Function} props.dispatch - The function to update blocks state.
 *
 * @returns {React.Provider} - The block editor context provider.
 */
export function BlockEditorContextProvider({
  blocks,
  config,
  dispatch,
  ...props
} = {}) {
  const updateBlock = React.useCallback(
    (
      blockId,
      { actionType = actionTypes.update, content, contentParams } = {},
    ) => {
      if (typeof blockId !== 'string') {
        throw new Error(
          `Expected blockId string. Received blockId type "${typeof blockId}".`,
        )
      }

      validateContent({ content, contentParams })

      dispatch({
        actionType: actionType || actionTypes.update,
        blockId,
        content,
        contentParams,
        supportedBlocks: config.blockTypes,
      })
    },
    [config.blockTypes, dispatch],
  )

  const updateBlocks = React.useCallback(
    (callback, actionType) => {
      if (typeof callback === 'function') {
        dispatch({ actionType: actionType || actionTypes.update, callback })
      } else {
        throw new Error(
          `Expected callback to be a function. Received callback type "${typeof callback}".`,
        )
      }
    },
    [dispatch],
  )

  const deleteBlock = React.useCallback(
    (blockId) => {
      updateBlocks(
        (prevState) => {
          const array = [...prevState]
          const removeIndex = prevState
            .map((item) => item.block_id)
            .indexOf(blockId)
          array.splice(removeIndex, 1)
          return array
        },
        { actionType: actionTypes.delete },
      )
    },
    [updateBlocks],
  )

  const insertNewBlock = React.useCallback(
    ({ index, type }) => {
      updateBlocks(
        (prevState) => {
          // Prevent inserting in front of a readonly block
          if (
            prevState.length &&
            index === 0 &&
            prevState[0].type === config.blockTypes.readonly?.name
          ) {
            // eslint-disable-next-line no-param-reassign
            index += 1
          }
          const newState = [...prevState]
          const blockObject = {
            block_id: newId(),
            content: { ...config.blockTypes[type].initialContentState },
            type,
          }
          newState.splice(index, 0, blockObject)
          return newState
        },
        { actionType: actionTypes.create },
      )
    },
    [config.blockTypes, updateBlocks],
  )

  const moveBlock = React.useCallback(
    (sourceIndex, destinationIndex) => {
      if (
        typeof sourceIndex === 'number' &&
        typeof destinationIndex === 'number' &&
        sourceIndex !== destinationIndex
      ) {
        updateBlocks(
          (prevState) => {
            // Don't allow moving in front of a readonly block
            if (
              destinationIndex === 0 &&
              prevState[0].type === config.blockTypes.readonly?.name
            ) {
              return prevState
            }
            return arrayMove(prevState, sourceIndex, destinationIndex)
          },
          { actionType: actionTypes.move },
        )
      }
    },
    [config.blockTypes, updateBlocks],
  )

  const value = React.useMemo(
    () => ({
      blockReducer,
      blocks,
      config,
      deleteBlock,
      insertNewBlock,
      moveBlock,
      updateBlock,
      updateBlocks,
    }),
    [
      blocks,
      config,
      deleteBlock,
      insertNewBlock,
      moveBlock,
      updateBlock,
      updateBlocks,
    ],
  )

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <BlockEditorContext.Provider value={value} {...props} />
}

BlockEditorContextProvider.propTypes = blockEditorContextProps.propTypes
BlockEditorContextProvider.defaultProps = blockEditorContextProps.defaultProps
