/**
 * @module SoundCloudBlock
 */
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Box, Collapse, Container, Skeleton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { BaseBlock } from 'components/blocks/base-block'
import { blockContentErrorProps } from 'constants/prop-types'

const useStyles = makeStyles({
  audioPlaceholder: {
    height: 250,
    width: '100%',
  },
  hidden: {
    visibility: 'hidden',
  },
})

/**
 * @typedef SoundCloudObject
 * @property {string} soundcloud_embed_url - The SoundCloud track's embed url.
 */

/**
 * SoundCloud Audio Block Component.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.className] - Optional additional className string.
 * @param {SoundCloudObject} props.content - The video block content object.
 * @param {object} props.dragHandleProps - Object provided by React Beautiful DND to create a compatible drag handle.
 * @param {boolean} props.isDragging - Provided by React Beautiful DND to indicate the block is being dragged.
 * @param {Function} props.onDelete - The delete handler.
 *
 * @returns {React.ReactElement} - The video block component.
 *
 * @example <SoundCloudAudioBlock content={{ soundcloud_embed_url: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/393884172&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true" }} dragHandleProps={{}} isDragging={false} onDelete={() => {}} />
 */
export function SoundCloudAudioBlock({
  className,
  content,
  dragHandleProps,
  isDragging,
  onDelete,
}) {
  const [isLoaded, setIsLoaded] = React.useState(false)
  const { soundcloud_embed_url: soundcloudEmbedUrl } = content
  const classes = useStyles()

  return (
    <BaseBlock
      className={clsx('MUI-Block-Editor_block-soundcloud-audio', className)}
      dragHandleProps={dragHandleProps}
      isDragging={isDragging}
      onDelete={onDelete}
    >
      <Box>
        <Collapse in={Boolean(soundcloudEmbedUrl && isLoaded)}>
          <Container>
            {!isLoaded ? (
              <Skeleton
                className={classes.audioPlaceholder}
                variant="rectangular"
              />
            ) : null}
            <iframe
              className={clsx(!isLoaded ? classes.hidden : null)}
              frameBorder="no"
              height="250"
              onLoad={() => setIsLoaded(true)}
              scrolling="no"
              src={soundcloudEmbedUrl}
              width="100%"
            ></iframe>
          </Container>
        </Collapse>
      </Box>
    </BaseBlock>
  )
}

SoundCloudAudioBlock.propTypes = {
  className: PropTypes.string,
  content: PropTypes.shape({
    error: PropTypes.shape(blockContentErrorProps),
    soundcloud_embed_url: PropTypes.string.isRequired,
  }),
  dragHandleProps: PropTypes.object.isRequired,
  isDragging: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
}

SoundCloudAudioBlock.defaultProps = {
  className: null,
}
