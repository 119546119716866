/** @module blocksProps */
import PropTypes from 'prop-types'

export const blockContentProps = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
  ]).isRequired,
)
export const blocksProps = PropTypes.arrayOf(
  PropTypes.shape({
    block_id: PropTypes.string.isRequired,
    content: blockContentProps,
    type: PropTypes.string.isRequired,
  }).isRequired,
)
