/**
 * @module useAsyncImage
 */
import React from 'react'

/**
 * Hook that serves as a simple api to deliver the
 * state of an image from loading to loaded, while
 * caching the image.
 *
 * "Once an image has been loaded in any way into the browser,
 * it will be in the browser cache and will load much faster
 * the next time it is used whether that use is in the current
 * page or in any other page as long as the image is used before
 * it expires from the browser cache."
 * - https://stackoverflow.com/a/10240297.
 *
 * Then, after the image is cached, the api will return that
 * the image has been cached and is ready to be displayed.
 *
 * Finally, render the image and it'll pull from cache.
 *
 * @param {string} url - Image url to be loaded.
 *
 * @returns {object} - Object containing the pending, ready, and error state of the image.
 */
export default function useAsyncImage(url) {
  const [pending, setPending] = React.useState(true)
  const [ready, setIsReady] = React.useState(null)
  const [error, setError] = React.useState(null)

  function onLoad() {
    setIsReady(true)
    setPending(false)
  }

  function onError(e) {
    setError(e)
    setPending(false)
  }

  /* Cancel subscription if component unmounts https://juliangaramendy.dev/use-promise-subscription/ */
  React.useEffect(() => {
    function resetStateOnUrlChange() {
      setPending(true)
      setIsReady(null)
      setError(null)
    }

    let isSubscribed = true
    resetStateOnUrlChange()

    if (url) {
      try {
        const image = new Image()

        image.onload = () => {
          if (isSubscribed) {
            onLoad()
          }
        }
        image.onerror = () => {
          if (isSubscribed) {
            onError(new Error('Image failed to load properly'))
          }
        }

        image.src = url
      } catch (e) {
        onError(e)
      }
    }

    return () => {
      isSubscribed = false
    }
  }, [url])

  return {
    error,
    pending,
    ready,
  }
}
