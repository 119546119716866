/**
 * @module SortableList
 */
import React from 'react'
import PropTypes from 'prop-types'
import { List } from '@mui/material'
import { makeStyles } from '@mui/styles'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { sortDNDArray } from 'utils'

const useStyles = makeStyles({
  dragHandle: {
    cursor: 'move',
    userSelect: 'none',
  },
})

/**
 * The SortableRows component.
 *
 * @alias module:SortableList
 *
 * @param {object} props - The component props object.
 * @param {Array} props.children - An array of MUI ListItem component, or a custom implementation of a ListItem component like ContentRow.
 * @param {Function} props.onChange - The onChange handler.
 * @param {boolean} [props.useDragHandle] - Whether or not to use a drag handle.
 *
 * @returns {React.ReactElement} - The SortableList component.
 *
 * @example
 * import { ListItem, ListItemText } from '@mui/material'
 * import { SortableList } from '@youversion/react'
 *
 * function MySortableList() {
 *   const [data, setData] = React.useState([{id: 1, name: 'One'}, {id: 2, name: 'One'}, {id:3, name: 'Three'}])
 *
 *   return (
 *     <SortableList onChange={setData} useDragHandle={true}>
 *       {data.map((item) => (
 *         <ListItem key={item.id}>
 *           <ListItemText>{item.name}</ListItemText>
 *         </ListItem>
 *       ))}
 *     </SortableList>
 *   )
 * }
 */
export function SortableList({ children, onChange, useDragHandle }) {
  const classes = useStyles()

  return (
    <DragDropContext
      onDragEnd={(params) => sortDNDArray({ ...params, onChange })}
      useDragHandle={useDragHandle}
    >
      <Droppable droppableId="sortable-row-droppable">
        {(providedDroppable) => (
          <List
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...providedDroppable.droppableProps}
            ref={providedDroppable.innerRef}
          >
            {React.Children.map(children, (child, index) => {
              return (
                <Draggable
                  direction="vertical"
                  draggableId={child.key}
                  index={index}
                  key={child.key}
                >
                  {(providedDraggable) =>
                    React.cloneElement(child, {
                      innerRef: providedDraggable.innerRef,
                      startIcon: useDragHandle ? (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <div {...providedDraggable.dragHandleProps}>
                          <DragIndicatorIcon
                            aria-label="Move this element"
                            className={classes.dragHandle}
                          />
                        </div>
                      ) : null,
                      ...providedDraggable.draggableProps,
                      ...(!useDragHandle
                        ? providedDraggable.dragHandleProps
                        : {}),
                    })
                  }
                </Draggable>
              )
            })}
            {providedDroppable.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  )
}

SortableList.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  onChange: PropTypes.func.isRequired,
  useDragHandle: PropTypes.bool,
}

SortableList.defaultProps = {
  useDragHandle: false,
}
