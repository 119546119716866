/**
 * @module AddBlock
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Box, ClickAwayListener, Grow, IconButton, Popper } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { RoundedButton } from 'components/ui/buttons/rounded-button'
import { useBlocks } from 'context/block-editor-context'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  addBtn: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
    color: ({ open }) => (open ? theme.palette.primary.main : ''),
    transform: ({ open }) => (open ? 'rotate(45deg)' : ''),
    transition: `transform 0.25s ${theme.transitions.easing.easeInOut}`,
  },
  menuBtn: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    backgroundColor: 'white',
    boxShadow: theme.shadows[3],
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: '100%',
  },
  menuPopper: {
    zIndex: 4,
  },
  secondaryAddBtnContainer: {
    '& button $addBtn': {
      opacity: ({ open, isMainAddBlock }) => {
        if (isMainAddBlock) {
          return 1
        }

        if (open) {
          return 1
        }

        return 0.5
      },
      transition: `opacity ${theme.transitions.duration.enteringScreen}ms ${theme.transitions.easing.easeInOut}`,
    },
    '&:after': {
      backgroundColor: theme.palette.grey[300],
      borderRadius: 4,
      content: '""',
      height: 2,
      opacity: 0.5,
      position: 'absolute',
      // Same 48px and 12px as mentioned in the below comment.
      right: 'calc(48px + 12px)',
      transition: `opacity ${theme.transitions.duration.enteringScreen}ms ${theme.transitions.easing.easeInOut}`,
      // 48px is left and right side of block content, where drag handles and delete button are.
      // 12px is the padding inside of the block content.
      width: 'calc(100% - 48px * 2 - 12px * 2)',
      zIndex: 1,
    },
    '&:hover button $addBtn, & button:focus $addBtn': {
      opacity: 1,
      visibility: 'visible',
    },
    '&:hover:after': {
      opacity: 1,
    },
    alignItems: 'center',
    display: 'flex',
    height: 30,
    position: 'relative',
  },
}))

/**
 * Popover button containing buttons to add a new block at an index.
 * Defaults to index 0.
 *
 * @alias module:AddBlock
 *
 * @param {object} props - The component props object.
 * @param {boolean} props.disabled - Disables the add block button.
 * @param {boolean} props.initialOpen - An optional initialOpen state. Defaults to false.
 * @param {number} props.insertAtIndex - An optional index at which to insert the new block. Defaults to 0.
 * @param {boolean} props.isMainAddBlock - If enabled, a larger add block button will appear. If disabled, the button will be smaller and hidden until hovered upon.
 * @param {string} props.placement - An optional placement of the popover. Defaults to 'top-start'. See https://material-ui.com/api/popper/ for valid params.
 * @param {number} props.transitionDelay - An optional number of milliseconds to delay the menu animation transitions. Defaults to 150.
 *
 * @returns {React.ReactElement} - The AddBlock popover component.
 *
 * @example
 * <AddBlock initialOpen={false} insertAtIndex={0} placement="top-start" transitionDelay={150} />
 */
export function AddBlock({
  disabled,
  initialOpen,
  insertAtIndex,
  isMainAddBlock,
  placement,
  transitionDelay,
}) {
  const addBlockButtonRef = React.useRef(null)
  const [open, setOpen] = React.useState(false)
  const classes = useStyles({ isMainAddBlock, open })

  const { config, insertNewBlock } = useBlocks()

  function handleToggle() {
    setOpen((prevState) => !prevState)
  }

  function handleClickAway() {
    if (!initialOpen) {
      setOpen(false)
    }
  }

  // anchor element is not set until first render
  // then we can setOpen to true if initialOpen prop
  // is true. Otherwise, the menu has no anchor to
  // set its placement.
  React.useEffect(() => {
    if (addBlockButtonRef.current && initialOpen) {
      setOpen(true)
    }
  }, [addBlockButtonRef, initialOpen])

  const addBlockBtn = (
    <IconButton
      aria-label={config?.strings?.addBlock ?? 'Add block'}
      disabled={disabled || initialOpen}
      onClick={handleToggle}
      ref={addBlockButtonRef}
    >
      <AddCircleOutlineIcon
        className={classes.addBtn}
        fontSize={isMainAddBlock ? 'large' : 'default'}
      />
    </IconButton>
  )

  return (
    <>
      <Popper
        className={classes.menuPopper}
        anchorEl={addBlockButtonRef.current}
        open={open}
        placement={placement}
        popperOptions={{ eventsEnabled: false }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box display="flex" flexDirection="column" minWidth="200px">
            {Object.keys(config.blockTypes)
              .filter((type) => !config.blockTypes[type].disableCreate) // hide specific blocks from creation
              .map((type, index) => {
                const { Icon: BlockIcon } = config.blockTypes[type]
                return (
                  <Grow
                    in={true}
                    key={`add_${type}_button_at_index_${insertAtIndex}`}
                    timeout={{
                      enter: (index + 1) * transitionDelay, // in milliseconds
                      exit: (index + 1) * transitionDelay,
                    }}
                  >
                    <RoundedButton
                      className={classes.menuBtn}
                      size="large"
                      onClick={() => {
                        insertNewBlock({ index: insertAtIndex, type })
                        setOpen(false)
                      }}
                      startIcon={BlockIcon ? <BlockIcon /> : null}
                      disabled={disabled}
                    >
                      {config.blockTypes[type].addLabel ??
                        `Add ${config.blockTypes[type].displayName ?? type}`}
                    </RoundedButton>
                  </Grow>
                )
              })}
          </Box>
        </ClickAwayListener>
      </Popper>
      {isMainAddBlock ? (
        addBlockBtn
      ) : (
        <div className={classes.secondaryAddBtnContainer}>{addBlockBtn}</div>
      )}
    </>
  )
}

AddBlock.propTypes = {
  /** Disables the add block button. */
  disabled: PropTypes.bool,
  /** An optional initialOpen state. Defaults to false. */
  initialOpen: PropTypes.bool,
  /** An optional index at which to insert the new block. Defaults to 0. */
  insertAtIndex: PropTypes.number,
  /** If enabled, a larger add block button will appear. If disabled, the button will be smaller and hidden until hovered upon. */
  isMainAddBlock: PropTypes.bool,
  /** An optional placement of the popover. Defaults to 'top-start'. See https://material-ui.com/api/popper/ for valid params. */
  placement: PropTypes.string,
  /** An optional number of milliseconds to delay the menu animation transitions. Defaults to 150. */
  transitionDelay: PropTypes.number,
}

AddBlock.defaultProps = {
  disabled: false,
  initialOpen: false,
  insertAtIndex: 0,
  isMainAddBlock: false,
  placement: 'right-start',
  transitionDelay: 150,
}
