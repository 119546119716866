/**
 * @module DropAreaEmpty
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button as MuiButton, Typography } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

/**
 * Display this component while the drop area is empty and awaiting files to be dropped or loaded.
 *
 * @alias module:DropAreaEmpty
 *
 * @param {object} props - Component props object.
 * @param {React.ReactElement} [props.button] - An optional button component.
 * @param {string|React.ReactElement} [props.description] - Custom text string or React element to let the users know what to do.
 * @param {React.ReactElement} [props.icon] - Material UI icon to display in the file drop area.
 * @param {string} [props.buttonText] - The text for the upload button.
 *
 * @returns {React.ReactElement} - The visual component for the drop area's "empty" state.
 */
export function DropAreaEmpty({
  button: Button,
  description,
  icon: Icon,
  buttonText,
}) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Icon fontSize="large" color="action" />
      <Box my={2}>
        <Button color="primary" variant="contained">
          {buttonText || 'Choose file(s) to upload'}
        </Button>
      </Box>
      {typeof description === 'string' ? (
        <Typography color="textSecondary">
          {description || 'Or drag and drop your file here'}
        </Typography>
      ) : (
        description || 'Or drag and drop your file here'
      )}
    </Box>
  )
}

DropAreaEmpty.propTypes = {
  button: PropTypes.elementType,
  buttonText: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.elementType,
}

DropAreaEmpty.defaultProps = {
  button: MuiButton,
  buttonText: '',
  description: '',
  icon: CloudUploadIcon,
}
