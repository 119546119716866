/**
 * @module arrayToObject
 */

/**
 * Transforms an array of objects into an object with key values derived from the specified `keyProperty` value.
 *
 * @param {Array.<object>} a - An array of objects.
 * @param {string|number} keyProperty - The key value from each of the array objects to be used for the return object's key values.
 *
 * @alias module:arrayToObject
 *
 * @throws {Error} - Throws an error if `a` is not an array.
 * @throws {Error} - Throws an error if `a` is an empty array.
 * @throws {Error} - Throws an error if `keyProperty` is not a string.
 * @throws {Error} - Throws an error if `arrayToObject` is not an  array of objects.
 * @throws {Error} - Throws an error if the value of `a[keyProperty]` is not a number or string.
 *
 * @example
 * // Returns { jane: { first_name: 'jane', last_name: 'doe' }, john: { first_name: 'john', last_name: 'doe' } }
 * const myNewObject = arrayToObject([{ first_name: 'jane', last_name: 'doe' }, { first_name: 'john', last_name: 'doe' }], 'first_name')
 *
 * @returns {object} - Returns object with key/value pairs.
 */
export function arrayToObject(a, keyProperty) {
  if (!Array.isArray(a)) {
    if (['null', 'undefined'].indexOf(typeof a) === -1) {
      return {}
    }
    throw new Error('Passed a non-array to `arrayToObject`.')
  }
  if (a.length === 0) {
    throw new Error('Array cannot be empty.')
  }

  if (typeof keyProperty !== 'string') {
    throw new Error('`arrayToObject` expects `keyProperty` to be a string.')
  }

  return a.reduce((prev, curr) => {
    const key = curr[keyProperty]

    if (typeof curr !== 'object') {
      throw new Error('`arrayToObject` only supports arrays of objects.')
    }

    if (['number', 'string'].indexOf(typeof key) === -1) {
      throw new Error(
        '`arrayToObject` expects value of a[keyProperty] to be a number or string.',
      )
    }

    return Object.assign(prev, { [key]: curr })
  }, {})
}
