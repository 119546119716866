/**
 * @module serializeSoundCloudAudio
 */

/**
 * @typedef SoundCloudObject
 * @property {string} [soundcloud_embed_url] - The SoundCloud track's embed url.
 */

/**
 * Serialize the provided SoundCloud audio content.
 *
 * @alias module:serializeSoundCloudAudio
 *
 * @param {SoundCloudObject} content - The content to serialize.
 *
 * @returns {string} The serialized SoundCloud audio content.
 */
export function serializeSoundCloudAudio(content) {
  const { soundcloud_embed_url } = content

  if (!soundcloud_embed_url) {
    return null
  }

  return `<iframe 
  frameBorder="no"
  height="250"
  scrolling="no"
  src="${soundcloud_embed_url}">
  width="100%"
  </iframe>`
}
