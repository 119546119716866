/**
 * @module AsyncButton
 */
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { CircularProgress, Button as MuiButton, Tooltip } from '@mui/material'
import { makeStyles, withStyles } from '@mui/styles'
import { red } from '@mui/material/colors'

import { statusTypes } from '@youversion/utils'

const useStyles = makeStyles(() => {
  return {
    circularProgressLarge: {
      marginInlineStart: -15,
      marginBlockStart: -15,
    },
    circularProgressLargeIcon: {
      marginInlineStart: 13,
      marginBlockStart: -15,
    },
    circularProgressMedium: {
      marginInlineStart: -13,
      marginBlockStart: -13,
    },
    circularProgressMediumIcon: {
      marginInlineStart: 8,
      marginBlockStart: -13,
    },
    circularProgressSmall: {
      marginInlineStart: -12,
      marginBlockStart: -11,
    },
    circularProgressSmallIcon: {
      marginInlineStart: 5,
      marginBlockStart: -11,
    },
    iconProgress: {
      insetInlineStart: 0,
    },
    progress: {
      position: 'absolute',
      insetBlockStart: '50%',
    },
    regularProgress: {
      insetInlineStart: '50%',
    },
  }
})

/**
 * Returns the correct root error styles for a Material-UI `<Button />`.
 *
 * @param {object} [params] - The function params.
 * @param {string} [params.errorColor] - A hex color value to use.
 * @param {('contained'|'outlined'|'text')} [params.variant] - A material-ui button variant.
 *
 * @throws {Error} - Throws an error if `color` is not a hex value.
 *
 * @returns {object} - The styles object.
 *
 * @example
 * const errorStyles = getErrorStyles({ errorColor: '#e80033', variant: 'contained' })
 */
export function getErrorStyles({ errorColor: color, variant }) {
  // Check for 7-character hex code.
  if (color && !(color.length === 7 && /^#[0-9a-fA-F]+$/.test(color))) {
    throw new Error(
      '`errorColor` must be a valid 7-character hex color code. Example: `#e80033`',
    )
  }
  const errorColor = color || red[500]
  const errorStyles = {
    root: { color: errorColor },
  }

  if (variant === 'contained') {
    Object.assign(errorStyles.root, {
      backgroundColor: errorColor,
      '&:hover': {
        backgroundColor: errorColor,
      },
      color: '#fff',
    })
  }

  if (variant === 'outlined') {
    Object.assign(errorStyles.root, {
      borderColor: errorColor,
      '&:hover': {
        backgroundColor: `${errorColor}0D`, // `0D` is 5% opacity.
        borderColor: errorColor,
      },
    })
  }
  return errorStyles
}

/**
 * Wrapping component to supply error styles to a button component.
 * Returns an untouched button if `hasError` is false.
 *
 * @param {object} props - The component props.
 * @param {React.ReactChild} props.children - The component child. Must be a material-ui `<Button />` component.
 * @param {string} [props.errorColor] - An error color hex value.
 * @param {boolean} [props.hasError] - Whether or not the component has an error.
 *
 * @returns {React.ReactElement} - The ErrorStyles component and children.
 *
 * @example
 * function MyComponent() {
 *   const [error, setError] = React.useState()
 *
 *   return (
 *     <ErrorStyles errorColor="#e80033" hasError={Boolean(error)}>
 *       <Button onClick={() => setError('There was an error')} variant="contained" />
 *     </ErrorStyles>
 *   )
 * }
 */
export function ErrorStyles({ children, errorColor, hasError }) {
  if (!hasError) {
    return <>{children}</>
  }
  const Component = React.Children.only(children)
  const { className, variant } = Component.props

  const errorStyles = getErrorStyles({ errorColor, variant })

  const StyledComponent = withStyles(errorStyles)(({ classes }) => {
    return React.cloneElement(Component, {
      className: clsx(className, classes.root),
    })
  })

  return <StyledComponent />
}

ErrorStyles.propTypes = {
  children: PropTypes.element.isRequired,
  errorColor: PropTypes.string,
  hasError: PropTypes.bool,
}

ErrorStyles.defaultProps = {
  errorColor: null,
  hasError: false,
}

/**
 * The StatelessAsyncButton UI component.
 *
 * @param {object} props - The component props object.
 * @param {React.ElementType} [props.component] - The root button component to render. Defaults to material-ui <Button />.
 * @param {boolean} [props.disabled] - Whether or not the button is disabled.
 * @param {string} [props.errorColor] - A hex color value. Example: `'#e80033'` or `red[500]`.
 * @param {React.ElementType} [props.icon] - The button icon.
 * @param {(string|boolean)} [props.idle] - The idle state. If a string is provided, it will be shown instead of the default 'Save'.
 * @param {(string|boolean)} [props.pending] - The pending state. If a string is provided, it will be shown instead of the default 'Saving'.
 * @param {(string|boolean)} [props.rejected] - The rejected state. If a string is provided, it will be shown instead of the default 'Error. Try again?'.
 * @param {(string|boolean)} [props.resolved] - The resolved state. If a string is provided, it will be shown instead of the default 'Saved!'.
 * @param {('small'|'medium'|'large')} [props.size] - The button size.
 * @param {string} [props.tooltip] - A tooltip to display on hover.
 * @param {string} [props.tooltipPlacement] - The Material-UI tooltip placement. Defaults to 'top'.
 *
 * @returns {React.ReactElement} - The AsyncButton component.
 *
 * @example
 * import React from 'react'
 * import CloudUploadIcon from '@mui/icons-material/CloudUpload'
 * import { StatelessAsyncButton, RoundedButton } from '@youversion/react'
 * import { statusTypes } from '@youversion/utils'
 * import { apiCall } from './api-call'
 *
 * function MyComponent({ file }) {
 *   const [status, setStatus] = React.useState(statusTypes.IDLE)
 *
 *   async function handleUpload() {
 *     setStatus(statusTypes.PENDING)
 *     try {
 *       await apiCall(file)
 *       setStatus(statusTypes.RESOLVED)
 *     } catch (error) {
 *       setStatus(statusTypes.REJECTED)
 *     }
 *   }
 *
 *   return (
 *     <StatelessAsyncButton
 *       component={RoundedButton}
 *       disabled={!file}
 *       errorColor="#e80033"
 *       icon={CloudUploadIcon}
 *       idle={status === statusTypes.IDLE ? 'Upload' : null}
 *       onClick={handleUpload}
 *       pending={status === statusTypes.PENDING ? 'Uploading' : null}
 *       rejected={Boolean(status === statusTypes.REJECTED)} // Uses default string.
 *       resolved={status === statusTypes.RESOLVED ? 'Uploaded!' : null}
 *       size="medium"
 *       tooltip={!file ? 'Please select a file before uploading.' : null}
 *       tooltipPlacement="top-end"
 *       {...componentProps} // Any other props supported by the `component` prop element. Defaults to material-ui `<Button />` props.
 *     />
 *   )
 * }
 */
export function StatelessAsyncButton({
  component: Button,
  disabled,
  errorColor,
  icon: Icon,
  idle,
  pending,
  rejected,
  resolved,
  size,
  tooltip,
  tooltipPlacement,
  ...props
}) {
  const classes = useStyles()

  let spinnerSize = 26
  let spinnerClass = Icon
    ? classes.circularProgressMediumIcon
    : classes.circularProgressMedium

  if (size === 'small') {
    spinnerSize = 22
    spinnerClass = Icon
      ? classes.circularProgressSmallIcon
      : classes.circularProgressSmall
  }

  if (size === 'large') {
    spinnerSize = 30
    spinnerClass = Icon
      ? classes.circularProgressLargeIcon
      : classes.circularProgressLarge
  }

  let buttonChildren = typeof idle === 'string' ? idle : 'Save'

  if (pending) {
    buttonChildren = (
      <>
        {typeof pending === 'string' ? pending : 'Saving'}
        <CircularProgress
          className={clsx(
            classes.progress,
            Icon ? classes.iconProgress : classes.regularProgress,
            spinnerClass,
          )}
          size={spinnerSize}
        />
      </>
    )
  }

  if (resolved) {
    buttonChildren = typeof resolved === 'string' ? resolved : 'Saved!'
  }

  if (rejected) {
    buttonChildren =
      typeof rejected === 'string' ? rejected : 'Error. Try again?'
  }

  return (
    <Tooltip
      disableHoverListener={Boolean(!tooltip)}
      placement={tooltipPlacement}
      title={tooltip}
    >
      <span>
        <ErrorStyles errorColor={errorColor} hasError={Boolean(rejected)}>
          <Button
            disabled={Boolean(disabled || pending || resolved)}
            size={size}
            startIcon={Icon ? <Icon /> : null}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          >
            {buttonChildren}
          </Button>
        </ErrorStyles>
      </span>
    </Tooltip>
  )
}

StatelessAsyncButton.propTypes = {
  component: PropTypes.elementType,
  disabled: PropTypes.bool,
  errorColor: PropTypes.string,
  icon: PropTypes.elementType,
  idle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  pending: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  rejected: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  resolved: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  tooltip: PropTypes.string,
  tooltipPlacement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top',
  ]),
}

StatelessAsyncButton.defaultProps = {
  component: MuiButton,
  disabled: false,
  errorColor: null,
  icon: undefined,
  idle: null,
  pending: null,
  rejected: null,
  resolved: null,
  size: 'medium',
  tooltip: '',
  tooltipPlacement: 'top',
}

/**
 * The AsyncButton that manages state internally.
 *
 * @alias module:AsyncButton
 *
 * @param {object} props - The component props.
 * @param {Function} props.onClick - The onClick handler.
 * @param {string} [props.idle] - Optional idle text.
 * @param {string} [props.pending] - Optional pending text.
 * @param {string} [props.rejected] - Optional rejected text.
 * @param {string} [props.resolved] - Optional resolved text.
 * @param {object} [props.options] - Options.
 * @param {boolean} [props.options.disableUpdateOnSuccess] - Disables firing a state update after a successful async resolve. This mitigates an unmounted component update error if the component is unmounted immediately on success. For example, navigating to a new page on success will unmount the button and throw a memory leak error.
 *
 * @returns {React.ReactElement} - The StatefulAsyncButton component.
 *
 * @example
 * import { AsyncButton } from '@youversion/react'
 * import { apiCall } from './api-call'
 *
 * function MyComponent({ file }) {
 *   return (
 *     <AsyncButton
 *       idle="Upload"
 *       onClick={() => apiCall(file)}
 *       options={{
 *         disableUpdateOnSuccess: true
 *       }}
 *       pending="Uploading"
 *       rejected="Error. Retry?"
 *       resolved="Uploaded!"
 *       {...StatelessAsyncButtonProps} // Any other props supported by `StatelessAsyncButton`.
 *     />
 *   )
 * }
 */
export function AsyncButton({
  idle,
  onClick,
  options,
  pending,
  rejected,
  resolved,
  ...props
}) {
  const [status, setStatus] = React.useState(statusTypes.IDLE)

  async function handleClick() {
    try {
      await onClick()
      if (!options?.disableUpdateOnSuccess) {
        setStatus(statusTypes.RESOLVED)
      }
    } catch (error) {
      setStatus(statusTypes.REJECTED)
    }
  }

  return (
    <StatelessAsyncButton
      idle={status === statusTypes.IDLE ? idle : false}
      onClick={handleClick}
      pending={status === statusTypes.PENDING ? pending : false}
      rejected={status === statusTypes.REJECTED ? rejected : false}
      resolved={status === statusTypes.RESOLVED ? resolved : false}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}

AsyncButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  options: PropTypes.shape({
    disableUpdateOnSuccess: PropTypes.bool,
  }),
  idle: PropTypes.string,
  pending: PropTypes.string,
  rejected: PropTypes.string,
  resolved: PropTypes.string,
}

AsyncButton.defaultProps = {
  idle: null,
  options: undefined,
  pending: null,
  rejected: null,
  resolved: null,
}
