/**
 * @module BlockTypes
 */
import AudiotrackIcon from '@mui/icons-material/Audiotrack'
import CloudIcon from '@mui/icons-material/Cloud'
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'
import TextFieldsIcon from '@mui/icons-material/TextFields'
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary'
import {
  AudioBlock,
  ImageBlock,
  ReadOnlyHTMLBlock,
  SoundCloudAudioBlock,
  TextBlock,
  VideoBlock,
  YouTubeVideoBlock,
  YVVideoBlock,
} from 'components/blocks'
import { serializeAudio } from 'serializers/audio-serializer'
import { serializeImage } from 'serializers/image-serializer'
import { serializeSoundCloudAudio } from 'serializers/soundcloud-audio-serializer'
import { serializeText } from 'serializers/text-serializer'
import { serializeVideo } from 'serializers/video-serializer'
import { serializeYVVideo } from 'serializers/yv-video-serializer'

export const defaultBlockTypes = {
  audio: {
    Component: AudioBlock,
    displayName: 'Audio',
    Icon: AudiotrackIcon,
    initialContentState: {
      file_id: '',
      postprocessing_url: '',
      source_url: '',
    },
    name: 'audio',
    serialize: serializeAudio,
  },
  image: {
    Component: ImageBlock,
    displayName: 'Image',
    Icon: PhotoLibraryIcon,
    initialContentState: {
      alt: '',
      display_url: '',
      file_id: '',
      source_url: '',
    },
    name: 'image',
    serialize: serializeImage,
  },
  readonly: {
    Component: ReadOnlyHTMLBlock,
    disableCreate: true,
    displayName: 'Read Only',
    Icon: undefined,
    initialContentState: { html: '' },
    name: 'readonly',
  },
  soundcloud_audio: {
    Component: SoundCloudAudioBlock,
    disableCreate: true,
    displayName: 'SoundCloud Audio',
    Icon: CloudIcon,
    initialContentState: {
      soundcloud_embed_url: '',
    },
    name: 'soundcloud_audio',
    serialize: serializeSoundCloudAudio,
  },
  text: {
    Component: TextBlock,
    displayName: 'Text',
    Icon: TextFieldsIcon,
    initialContentState: { html: '' },
    name: 'text',
    serialize: serializeText,
  },
  video: {
    Component: VideoBlock,
    displayName: 'Video',
    Icon: VideoLibraryIcon,
    initialContentState: {
      file_id: '',
      source_url: '',
      youtube_video_id: '',
    },
    name: 'video',
    serialize: serializeVideo,
  },
  youtube_video: {
    Component: YouTubeVideoBlock,
    displayName: 'YouTube Video',
    Icon: VideoLibraryIcon,
    initialContentState: {
      youtube_video_id: '',
    },
    name: 'youtube_video',
    serialize: serializeVideo,
  },
  yv_video: {
    Component: YVVideoBlock,
    displayName: 'Video',
    Icon: VideoLibraryIcon,
    initialContentState: {
      file_id: '',
      poster_url: '',
      source_url: '',
      sources: [],
    },
    name: 'yv_video',
    serialize: serializeYVVideo,
  },
}

export const BlockComponents = {}
