/**
 * @module DropAreaUploading
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Box, LinearProgress } from '@mui/material'

/**
 * Display this component while the `onDrop` function is busy handling an asset upload.
 *
 * @alias module:DropAreaUploading
 *
 * @param {object} props - Component props object.
 * @param {string|React.ReactElement} [props.description] - The drop area uploading state's descriptive text.
 *
 * @returns {React.ReactElement} - The visual component for the drop area's "uploading" state. (E.g., what gets displayed while a file is uploading or finalizing).
 */
export function DropAreaUploading({ description }) {
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width="100%"
    >
      <Box width="50%" mb={2}>
        <LinearProgress />
      </Box>
      {typeof description === 'string' ? (
        <Typography color="textSecondary">{description}</Typography>
      ) : (
        description
      )}
    </Box>
  )
}

DropAreaUploading.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

DropAreaUploading.defaultProps = {
  description: 'Uploading…',
}
