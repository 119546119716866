/**
 * @module DropAreaEmpty
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { RoundedButton } from 'components/ui/buttons/rounded-button'

/**
 * Display this component while your Block is empty and awaiting files
 * to be dropped or loaded.
 *
 * @param {object} props - Component props object.
 * @param {React.ReactElement} props.muiIcon - Material UI icon to display in the file drop area.
 * @param {string} props.fileType - Type of file to be uploaded. (i.e. 'image', 'audio', 'video').
 * @param {string} [props.uploadButtonCustomLabel] - Custom text string for upload button.
 * @param {string|React.ReactElement} [props.description] - Custom text string or React element to let the users know what to do.
 * @returns {React.ReactElement} - The visual component for a Block's "empty" state.
 */
export function DropAreaEmpty({
  muiIcon: DropAreaIcon,
  fileType,
  description,
  uploadButtonCustomLabel,
}) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <DropAreaIcon fontSize="large" color="action" />
      <Box mt={2} />
      <RoundedButton color="primary" variant="contained">
        {uploadButtonCustomLabel ?? 'UPLOAD'}
      </RoundedButton>
      <Box mt={2} />
      {typeof description === 'string' ? (
        <Typography color="textSecondary">
          {description ?? `Or drag and drop your ${fileType} file here`}
        </Typography>
      ) : (
        description
      )}
    </Box>
  )
}

DropAreaEmpty.propTypes = {
  /**
   * A list of custom requirements to guide users.
   *
   * Like "Only one image may be uploaded at a time.", "Only accepts jpg and png file types".
   */
  // customRequirements: PropTypes.arrayOf(PropTypes.string),
  /**
   * A title or message to display to users, so they know to click or drop files here.
   */
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** Type of file to be uploaded. (i.e. 'image', 'audio', 'video'). */
  fileType: PropTypes.string,
  /** Material UI icon to display in the file drop area. */
  muiIcon: PropTypes.elementType,
  /** Custom text string for upload button. */
  uploadButtonCustomLabel: PropTypes.string,
}

DropAreaEmpty.defaultProps = {
  description: '',
  fileType: '',
  muiIcon: CloudUploadIcon,
}
